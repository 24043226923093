.footer_print{
    display:none;
    font-size:10px;
    padding: 10px 0;
    img{
        max-width: 70px;
        margin-right: 30px;
    }
}
@media print {
    a{
        text-decoration: none !important;
    }

    #downloads_list{
        display: block !important; // Firefox bug truncate tables
    }
    section.section{
        .tabs{
            display:none !important;
        }
    }
    .backtop, .btn_print, form, input, select, .select2, .field--name-field-image-entete > img, .nav.nav-tabs, .button_stack, .btn.analyse{
        display:none !important;
    }
    ol.breadcrumb{
        display:none !important;
    }
    .breadcrumb-item{
        display:none !important;
    }
    .path-node #pagehead, .path-user #pagehead, .path-recherche #pagehead, .node--type-page #pagehead, .path-sitemap #pagehead {
        padding: 30px 0 40px 0;
        .field--name-field-titre-de-la-page, .field--name-field-chapo{
            color: #1A3088 !important;
        }
    }
    .navbar-brand img {
        height: 60px !important;
        position:relative;
        left:-40px;
    }
    .field--name-field-chapo,
    #tarteaucitronRoot{
        display:none;
    }
    body{
        padding:0;
    }
    #page-wrapper{
        margin:0;
        padding:0;
    }
    #main-back{
        display:none;
    }

    .path-node #pagehead h1 .field--name-field-titre-de-la-page, 
    .path-user #pagehead h1 .field--name-field-titre-de-la-page, 
    .path-recherche #pagehead h1 .field--name-field-titre-de-la-page, 
    .node--type-page #pagehead h1 .field--name-field-titre-de-la-page, 
    .path-sitemap #pagehead h1 .field--name-field-titre-de-la-page,
    #navbar-main > .container-fluid {
        padding-left: 0px;
    }
    .footer_print{
        display:block !important;
        padding:10px;
        width:100%;
        border: 1px solid #efeff2;
        text-align: center;
        color:#1A3088;
    }
    #header{
        display: block !important;
        position: relative !important;
    }
    ul.nav, .menu_homepage, .language-switcher-language-url, .search-link, .mention_anonymous{
        display:none;
    }
    #navbar-main{
        min-height: 110px;
    }
    #header #navbar-main .navbar-brand{
        position: relative;
        top: 0;
    }

    table{
        width:100% !important;
        min-width:100% !important;
        border:1px solid #efeff2;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top:0 !important;
        table-layout: fixed !important;
        //page-break-inside:avoid;
        tr{
            //width:100vw !important;
            //min-width:100vw !important;
            &:nth-child(odd) td{
                background-color: #F3F3F6 !important;
                -webkit-print-color-adjust: exact;
                color-adjust:exact;
            }
        }
        th{
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
            //max-width:none !important;
            //width:auto !important;
            box-shadow: inset 0px 1px 0px 2px #efeff2 !important;
            border-top:0 !important;
            width:25% !important;
            td{
                border:none !important;
            }
        }
        td{
            border-bottom:1px solid #efeff2;
            font-size:12px !important;
            max-width:none !important;
            width:auto !important;
            overflow: hidden;
            word-wrap: break-word;
        }
    }
    // Prevent admin menu to add extra space
    #navbar-top {
        margin-top: 0 !important;
    }
    // Page liste des références - optimisation
    .page-node-432{
        table thead th[specifier="field_pays"],
        table thead th[specifier="field_licence"],
        table thead th[specifier="field_industry"],
        table tbody tr > td:first-child,
        table tbody tr > td:nth-child(5),
        table tbody tr > td:nth-child(6){
            max-width: 100px !important;
            width: 100px !important;
            min-width: auto !important;
        }
        table thead th[specifier="field_code_postal"],
        table tbody tr > td:nth-child(2){
            max-width: 60px !important;
            width: 60px !important;
            min-width: auto !important;
        }
    }

    #block-breadcrumbs{
        display: none;
    }
    
    #pagehead > .container-fluid,
    #downloads_list,
    .field--name-field-titre-de-la-page,
    h1{
        margin:0 !important;
        padding:0 !important;
    }
}