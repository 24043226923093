@font-face {
	font-family: "icons";
	src: url('../assets/fonts/icons/icons.eot');
	src: url('../assets/fonts/icons/icons.eot?#iefix') format('eot'),
		url('../assets/fonts/icons/icons.woff2') format('woff2'),
		url('../assets/fonts/icons/icons.woff') format('woff'),
		url('../assets/fonts/icons/icons.ttf') format('truetype'),
		url('../assets/fonts/icons/icons.svg#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-Phone:before {
	content: "\E001";
}

.icon-acces:before {
	content: "\E002";
}

.icon-back-to-top:before {
	content: "\E003";
}

.icon-catalogue:before {
	content: "\E004";
}

.icon-check:before {
	content: "\E005";
}

.icon-close-1:before {
	content: "\E006";
}

.icon-close-search:before {
	content: "\E007";
}

.icon-close:before {
	content: "\E008";
}

.icon-contact-adresse:before {
	content: "\E009";
}

.icon-contact-icon:before {
	content: "\E00A";
}

.icon-contact-tel:before {
	content: "\E00B";
}

.icon-contact:before {
	content: "\E00C";
}

.icon-creation-analyse:before {
	content: "\E00D";
}

.icon-dowlond-icon:before {
	content: "\E00E";
}

.icon-fleche-1:before {
	content: "\E00F";
}

.icon-fleche-2:before {
	content: "\E010";
}

.icon-fleche-3:before {
	content: "\E011";
}

.icon-fleche-4:before {
	content: "\E012";
}

.icon-fleche:before {
	content: "\E013";
}

.icon-gain-temps:before {
	content: "\E014";
}

.icon-home-alt:before {
	content: "\E015";
}

.icon-icon.php:before {
	content: "\E016";
}

.icon-icone accreditation:before {
	content: "\E017";
}

.icon-information:before {
	content: "\E018";
}

.icon-iso:before {
	content: "\E019";
}

.icon-logovalab:before {
	content: "\E01A";
}

.icon-loupe:before {
	content: "\E01B";
}

.icon-marqueur-1:before {
	content: "\E01C";
}

.icon-marqueur-multiple:before {
	content: "\E01D";
}

.icon-marqueur-select:before {
	content: "\E01E";
}

.icon-marqueur:before {
	content: "\E01F";
}

.icon-mode-fonctionnement:before {
	content: "\E020";
}

.icon-multilingue:before {
	content: "\E021";
}

.icon-plus:before {
	content: "\E022";
}

.icon-print:before {
	content: "\E023";
}

.icon-rechercher-1:before {
	content: "\E024";
}

.icon-rechercher:before {
	content: "\E025";
}

.icon-scroll:before {
	content: "\E026";
}

.icon-security:before {
	content: "\E027";
}

.icon-stars-line-blue:before {
	content: "\E028";
}

.icon-stars-line:before {
	content: "\E029";
}

.icon-stars:before {
	content: "\E02A";
}

.icon-talk-bubble:before {
	content: "\E02B";
}

.icon-time:before {
	content: "\E02C";
}

.icon-tracabilite:before {
	content: "\E02D";
}

.icon-valab.png:before {
	content: "\E02E";
}
