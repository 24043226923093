/**
*	Fullwidth Menu
*/
@include media-breakpoint-down(md) {
    // Prevent page scroll when menu is open
    html.menu-mobile-open, body.menu-mobile-open{
        overflow: hidden;
    }
    body.scrolled:not(.toolbar-horizontal) {
        #header {
            top: 0;
        }
    }
    // Make the nav full width
    #navbar-main{
        padding:0 !important;
    }
    // Logo
    .navbar-brand{
        position: relative !important;
        top: 0px !important;
        padding: 20px 0 20px 20px;
    }
    // Burger menu
    .navbar-toggler {
        top:20px !important;
    }
    // Search
    .search-link{
        position: absolute;
        right: 60px;
        top: 23px;
    }
    // Languages
    #block-selecteurdelangue{
        padding: 15px 0;
        .links{
            align-items: center;
            justify-content: center;
            li{
                &:after{
                    top: 1px;
                }
            }
        }
    }
    // Account menu
    #block-valab-account-menu.menu--account{
        ul.nav{
            text-align: center;
            height: 410px;
            a.nav-link{
                color: $valab-dark-blue;
                border-bottom: 1px solid rgba($valab-dark-blue,.3);
                // News
                &:first-child{

                }
                // Download
                &:nth-child(2){
                    display: inline !important;
                    border-top: none;
                }
                // Account
                &:nth-child(3){
                    border-bottom:none !important;
                    background-color: transparent !important;
                }
                // Last two links
                &:nth-child(3), &:last-child{
                    padding: 30px 0;
                    @include font-size(15);
                    justify-content: center;
                }
            }
            li.dropdown{
                background-color: transparent; 
            }
            li:nth-child(3){
                background-color: rgba(#1A3088,.1) !important;
                a{
                    border-bottom:none !important;
                    background-color: transparent !important;
                }
            }
            li:nth-child(4){
                background-color: rgba(#1A3088,.1) !important;
            }
            li{
                .caret-arrow{
                    right: -11px;
                }
            }
            li.dropdown-item{
                background-color: transparent !important; 
            }
        }
    }
    // Navbar wrapper
    #CollapsingNavbar{
        position:relative;
        border-radius: 0;
        // Menu and submenu  (on "next page")
        ul.navbar-nav{
            border-bottom: 1px solid rgba($valab-dark-blue,.3);
            border-top:1px solid rgba(#1D1D4F,.25);
            padding: 20px 0;
            display: block !important;
            .nav-item{
                padding: 15px 30px !important;
                display:flex;
                justify-content: space-between;
                width: 100vw;
                .nav-link {
                    transition: none !important;
                    white-space: pre-wrap;
                    font-family: 'ubuntuB' !important;
                    font-size: 19px !important;
                    padding: 0px !important;
                    max-width: 80vw;
                }
            }
        }
        &.show{
            min-height: 100vh;
            width: 200vw;
            #block-valab-main-menu{
                overflow-y: scroll;
                overflow-x:hidden;
                max-height: 100vh;
            }
            #block-valab-main-menu, ul.nav{
                flex-direction: column;
            }
            
        }
        .nav-item.dropdown{
            position: inherit;  // Allow submenu to go on right page
            .dropdown-menu{
                position: absolute;
                top: 0;
                left: 100vw;
                padding: 0 !important;
            }
        }
        .dropdown-menu.show{
            top: 0 !important;           // Show submenu on "next" page        
            box-shadow: none !important;
            width: 100%;
            min-height: 100vh;
            padding: 30px 0 !important;
            li{
                &:not(.title){ padding: 9px 30px !important };
                &.title{
                    font-family: 'ubuntuB';
                    font-size: 19px;
                    padding: 20px 20px 20px 30px;
                    color: $valab-dark-blue;
                    text-transform: uppercase;
                    position:relative;
                    border-top:1px solid rgba(#1D1D4F,.25);
                    .caret-arrow{
                        top: 17px;
                        left: -20px;
                        transform: rotate(90deg);
                    }
                }
                a{
                    font-family: 'ubuntuB' !important;
                    font-size: 19px !important;
                    padding: 0px !important;
                    &:after{
                        display:none !important; // Remove underlined
                    }
                }
            }
        }
    }
    // Arrow opener
    .caret-arrow{
        width:30px;
        height:30px;
        cursor:pointer;
        z-index: 999999;
        position: relative;
        top: -10px;
        right: -20px;
        background:url(../assets/icons/fleche-2.svg) no-repeat;
        transform: rotate(-90deg);
        background-size: 14px;
        background-position: 0 0px;
        display: inline-block;
        &:before {
            display:none;
        }
    }
    .nav-link {
        &:after{
            display:none; // Remove original drop down arrow
        }
    }
}



.user-logged-in {
	.menu--account {
		.nav-item{
			&:nth-child(4){
                a.nav-link{
                    background-color: transparent !important;
                    color:$valab-dark-blue !important;
                    border-bottom:0 !important;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        #block-valab-account-menu.menu--account{
            ul.nav{
                height: 450px;
            }
        }
    }
}

body:not(.user-logged-in) {
	.menu--account {
		.nav-item{
			&:nth-child(3){
                a.nav-link{
                    padding-top: 15px !important;
                    padding-bottom: 15px !important;
                }
            }
        }
    }
}