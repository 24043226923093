html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing : antialiased;
  font-smoothing         : antialiased;
  overflow-x             : hidden; // Prevent scroll when Wow is running
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

body {
  font-family: $font-family-base;
  color      : $valab-dark-blue;

  &:before {
    @extend .d-print-none;
    content                       : '';
    width                         : 100%;
    height                        : 2px;
    background                    : -webkit-linear-gradient(right, #00A5D8, #00C3FF, #1A3088);
    background                    : linear-gradient(to right, #00A5D8, #00C3FF, #1A3088);
    display                       : block;
    position                      : fixed;
    top                           : 0;
    z-index                       : 700;
    @include slideInLeft($duration: 1s, $count: 1, $delay: .2s, $function: ease-in, $fill: both);
  }
}

.dialog-off-canvas-main-canvas {
  position: relative;
  z-index : 500;

}

body:not(.page-node-432) {

  #main-wrapper,
  .featured-bottom {
    @include fadeIn($duration: 0.3s, $count: 1, $delay: .2s, $function: ease, $fill: both);

    &.leaving {
      @include fadeOut($duration: 0.3s, $count: 1, $delay: 0, $function: ease, $fill: both);
    }
  }
}



#page {
  margin-top: 127px;

  @include media-breakpoint-down(lg) {
    margin-top: 79px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 90px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 80px;
  }

  .highlighted {
    .alert {
      z-index: 99999;
      margin : 0;
    }
  }

  @media print {
    margin-top: 0;
  }
}

#main {
  @extend .p-0;
  min-height: 79.4vh;
}

// Headings

h1 {
  max-width  : unset !important;
  padding    : unset !important;
  font-weight: 500;
}

h2 {
  font-family: 'ubuntuM';
  @include font-size(24);
  margin-bottom: 25px;
  font-weight  : 500;
  color        : $valab-dark-blue-font;
}

h3 {
  font-family: 'ubuntuR';
  @include font-size(24);
  margin-bottom: 25px;
  color        : $valab-light-blue;
  font-weight  : 500;
}

h4 {
  font-family: 'ubuntuM';
  @include font-size(18);
  font-weight: 500;
}

h4,
h5,
h6 {
  font-weight: 500;
}

p {
  &+h3 {
    margin-top: 50px;
  }
}

b,
strong {
  font-family: "opensansSB";
  font-weight: 400;
}

table {
  color                  : $valab-dark-blue !important;
  background-color       : #ffffff;
  max-width              : 100%;
  width                  : auto;
  box-shadow             : 0 3px 25px #223C7340;
  border-top-left-radius : 5px;
  border-top-right-radius: 5px;
  @extend .w-100, .d-block, .d-lg-table, .table-striped;

  /*@include media-breakpoint-down(lg) {
		display: block !important;
		width: 100%;
		height:100%;
		overflow-x: auto;
		overflow-y: visible;
		-webkit-overflow-scrolling: touch;
	}*/
  thead {
    background-color: rgba(#223C73, 0.85);
    font-family     : 'opensansSB';
    @include font-size(12);
    text-transform: uppercase;
    color         : #ffffff;

    border th {

      &>a {
        position    : relative;
        color       : #fff;
        padding-left: 30px;

        &:before {
          content     : "\E00F";
          font-family : "icons";
          position    : absolute;
          top         : -8px;
          left        : -1px;
          width       : 20px;
          height      : 20px;
          //background:url(../assets/icons/fleche-2.svg) no-repeat !important;
          transform   : rotate(180deg);
          opacity     : .5;
        }

        &:after {
          content     : "\E00F";
          font-family : "icons";
          position    : absolute;
          top         : 6px;
          left        : 1px;
          width       : 20px;
          height      : 20px;
          //background:url(../assets/icons/fleche-2.svg) no-repeat !important;
          transform   : rotate(0deg);
          opacity     : .5;
        }
      }
    }

    a {
      font-family: 'opensansSB';
      @include font-size(12);
      text-transform: uppercase;
      color         : #ffffff;

      &:hover {
        color: #ffffff;
      }

      @media print {
        color: $valab-dark-blue-font !important;
      }
    }
  }

  th {
    border-bottom: none;
    border-top   : none !important;
    padding      : 18px 0.75rem !important;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }

    a {
      position    : relative;
      padding-left: 16px;

      &:before {
        content   : '';
        background: url(../assets/icons/fleche-3.svg) no-repeat;
        display   : block;
        top       : -3px;
        left      : 0;
        position  : absolute !important;
        width     : 10px;
        height    : 7px;
        transform : rotate(180deg);
        opacity   : .5;
      }

      &:after {
        content   : '';
        background: url(../assets/icons/fleche-3.svg) no-repeat;
        display   : block;
        top       : 12px !important;
        left      : 1px;
        position  : absolute !important;
        width     : 13px;
        height    : 7px;
        opacity   : .5;
      }

      .tablesort {
        display: none;
      }
    }

    &.is-active {
      a {

        &:before,
        &:after {
          background: url(../assets/icons/fleche-3.svg) no-repeat;
          opacity   : 1;
        }
      }
    }
  }

  .tablesort--desc {
    display: none;
  }

  td {
    font-family: 'opensansB';
    @include font-size(14);
    color     : #223C73;
    border-top: none !important;
    padding   : 18px 0.75rem !important;
  }

}

.table-striped tbody tr:nth-of-type(odd),
table tbody tr:nth-of-type(odd) {
  background-color: #F3F3F6;
}

.tablesort--asc {
  position        : absolute;
  top             : 6px;
  left            : 1px;
  width           : 20px;
  height          : 20px;
  background-image: none !important;

  &:before {
    content    : "\E00F";
    font-family: "icons";
    position   : absolute;
    top        : 0;
    right      : 0;
    width      : 20px;
    height     : 20px;
    color      : #fff;
  }
}

.tablesort--desc {
  position        : absolute;
  top             : -8px;
  left            : 0;
  width           : 20px;
  height          : 20px;
  background-image: none !important;

  &:before {
    content    : "\E00F";
    font-family: "icons";
    position   : absolute;
    top        : 0;
    right      : 0;
    width      : 20px;
    height     : 20px;
    color      : #fff;
    transform  : rotate(180deg);
  }
}

.field--name-body,
.modal {
  .document-interior-list {
    padding-top: 0 !important;
  }

  ul:not(.document-interior-list) {
    list-style: none;

    li {
      @extend .icon, .icon-check;
      position     : relative;
      margin-bottom: 10px;

      &:before {
        position : absolute;
        left     : -22px;
        color    : $valab-yellow;
        font-size: 13px;
        top      : 2px;
      }
    }
  }

  p.mea {
    background-color: #F3F3F6;
    padding         : 20px 20px;
    margin          : 20px 0;
  }

  img {
    @extend .img-fluid;
  }
}

// Tooltips
.tooltip-inner {
  max-width       : 240px;
  color           : #222;
  background-color: #fff;
  box-shadow      : 0px 3px 6px #1D1D4F40;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #fff;
}

// Form elements
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea.form-control,
select {
  color        : $valab-dark-blue;
  background   : #fff;
  border-radius: 10px;
  padding      : 10px;
  margin       : 0 10px 0 0;
  font-family  : 'opensansR';
  @include font-size(16);
  min-height: 52px;
  border    : none;

  &[type="submit"] {
    border-radius: 25px;
    background   : $valab-light-blue;
    color        : #fff;
    font-family  : 'ubuntuB';
    @include font-size(14);
    border   : none;
    min-width: 55px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color  : $valab-dark-blue;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color  : $valab-dark-blue;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color  : $valab-dark-blue;
    opacity: 1;
  }
}

.webform-button--submit {
  @extend .btn-linear; // Default button for all webforms
}

.form-actions.webform-actions {
  text-align: center;
  padding   : 20px 0;
}

.form-group {
  margin : 0 0 5px 0; // Default margin for all inputs
  padding: 0;
}

.webform-flexbox {
  &>div.webform-flex {
    margin: 0;

    .webform-flex--container {
      margin: 0;
    }
  }

  &>div.webform-flex:nth-child(2) {
    margin: 0 0 0 10px;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
}

// Footer
.site-footer {
  background   : #1D1D4F;
  position     : relative;
  margin-bottom: -1px;
  @extend .d-print-none;

  &.leaving {
    @include fadeOut($duration: 0.3s, $count: 1, $delay: 0, $function: ease, $fill: both);
  }

  .region-footer-fifth {
    padding: 20px 0;
    margin : 0 auto;
    @extend .container;

    @include media-breakpoint-down(sm) {
      padding-top: 50px !important;
    }

    // First footer nav
    nav#block-valab-footer {
      @extend .col-sm-5;

      ul {
        display        : flex;
        flex-direction : row;
        justify-content: flex-start;

        li {
          padding: 0 15px;

          a {
            color         : #fff;
            text-transform: uppercase;
            font-family   : 'ubuntuB';
            @include font-size(12);

            @include media-breakpoint-down(sm) {
              @include font-size(15);
            }
          }
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
      }
    }

    // Second footer nav
    nav#block-pieddepagesecondaire {
      @extend .col-sm-7;
      //@extend .icon, .icon-iso;
      position: relative;

      &:before {
        background     : url(/sites/default/files/2019-09/iso2x.png);
        background-size: 100% 100% !important;
        position       : absolute;
        content        : "";
        top            : -50px;
        right          : 50px;
        width          : 100px;
        height         : 100px;
        font-size      : 50px;

        @include media-breakpoint-down(sm) {
          top              : -180px;
          background-size  : 100% 100% !important;
          background-repeat: no-repeat;
          right            : 0;
          left             : auto;
        }
      }

      ul {
        display        : flex;
        flex-direction : row;
        justify-content: flex-end;
        padding-right  : 140px;

        @include media-breakpoint-down(sm) {
          padding-right : 0;
          flex-direction: column;
        }

        li {
          padding: 0;

          a {
            color      : #fff;
            font-family: 'ubuntuR';
            @include font-size(13);

            @include media-breakpoint-down(sm) {
              @include font-size(15);
            }
          }
        }
      }
    }
  }

}

// Back to top
.backtop {
  opacity   : 0;
  transition: all 0.3s ease-out;
  position  : fixed;
  right     : 40px;
  bottom    : 70px;
  font-size : 40px;
  z-index   : 30000;
  cursor    : pointer;
  @extend .d-none, .d-md-block;

  i {
    &:before {
      color: #00A5D8;
    }
  }
}

body.scrolled {
  .backtop {
    opacity                 : 1;
    @include pulse($duration: 1.5s, $count: infinite, $delay: .5s, $function: ease, $fill: both);
  }
}

// Select2
.select2-container--default .select2-selection--single {
  background-color: #fff;
  position        : relative;
  border          : none;
  border-radius   : 10px;
  height          : 53px;

  .select2-selection__arrow {
    background: url(../assets/icons/fleche-2.svg) no-repeat;
    display   : block;
    top       : 23px !important;
    right     : 6px !important;
    position  : absolute !important;

    b {
      display: none;
    }
  }

  &>span {
    line-height: 53px !important;
  }
}

.select2-dropdown {
  top       : 0px;
  border    : none;
  box-shadow: 2px 9px 12px #0000003b;
}

.select2-search--dropdown {
  padding: 11px 22px;
}

.select2-results {
  padding: 0 22px 22px 22px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $valab-light-blue;
  color           : white;
}

.select2-container {
  &:focus {
    outline: none;
  }

  .selection {
    &:focus {
      outline: none;
    }
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border       : 1px solid #223c72;
  border-radius: 6px;

  &:focus {
    outline: none;
  }
}

.select2-selection__rendered {
  color: #223c72;
}

.select2-selection {
  &:focus {
    outline: none;
  }
}

// Buttons
.btn {
  &:before {
    transition-property: color, background-image;
    transition-duration: 0.3s;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.blue {
    background: $valab-light-blue;

    &.bordered {
      border: 1px solid $valab-light-blue;
    }
  }

  &.small {
    padding: 5px 15px;
  }

  &.gray {
    background: $gray-400;

    &.bordered {
      border: 1px solid $gray-400;
    }
  }

  &.orange {
    background: $orange;
    color     : #fff;

    &:hover {
      border-color: $orange !important;
      color       : $orange !important;
    }
  }
}

.btn_print {
  @extend .btn, .small, .blue, .rounded, .icon, .icon-print;
  position: relative;
  padding : 5px 15px 5px 38px !important;
  color   : #fff;
  @include font-size(13);
  text-transform: uppercase;
  font-family   : 'ubuntuB';
  height        : 30px;
  margin        : 5px;
  border        : solid 1px transparent;

  &:hover {
    color           : #fff;
    border          : solid 1px $valab-light-blue;
    background-color: transparent;
    color           : $valab-light-blue;

    &:before {
      color: $valab-light-blue;
    }
  }

  &:before {
    @include font-size(20);
    color   : #fff;
    position: absolute;
    top     : -1px;
    left    : 11px;
    width   : 19px;
    height  : 21px;
  }
}

.unsubscribe {
  @extend .btn_print;

  &:before {
    @extend .icon-stars;
  }
}

.subscribe {
  @extend .btn_print;

  &:before {
    background-image : url(../assets/icons/stars-line.svg);
    content          : '';
    display          : block;
    background-size  : 100%;
    background-repeat: no-repeat;
    top              : 4px;
  }

  &:hover {
    &:before {
      @extend .icon-stars;
      background-image: none;
      top             : -2px;
    }
  }

  &.home {
    color           : $orange;
    border          : 1px solid $orange;
    background-color: #fff;

    &:before {
      background-image: url(../assets/icons/stars-line-orange.svg);
    }

    &:hover {
      &:before {
        display: none;
      }
    }
  }
}

.analyse {
  @extend .btn-linear, .my-auto;
  padding    : 5px 18px 5px 30px !important;
  margin-left: 5px;

  &:after {
    font-family: "icons" !important;
    content    : "\E00E" !important;
    left       : 0;
    position   : absolute;
    left       : 13px;
    top        : 4px;
    @include font-size(16);
  }
}

.btn-bordered {
  border       : solid 1px rgba(255, 255, 255, 0.25);
  padding      : 20px 26px;
  border-radius: 50px;
  color        : #ffffff;
  font-family  : 'ubuntuM';
  margin-top   : 34px;
  @include font-size(17);
  text-align : center;
  line-height: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color           : #02729c;
  }
}

.btn-linear {
  color        : #ffffff;
  border       : none;
  border-radius: 40px;
  @include font-size(17);
  font-family     : 'ubuntuM';
  text-align      : center;
  line-height     : 20px;
  padding         : 15px 22px;
  background-image: linear-gradient(to right, #18398E, #00A5D8) !important;
  position        : relative;
  z-index         : 100;
  transition      : all 0.3s ease-in;
  display         : inline-block;
  margin-top      : 10px;

  &:before {
    border-radius   : inherit;
    background-image: linear-gradient(to right, #18398E, #18398E);
    content         : '';
    display         : block;
    height          : 100%;
    position        : absolute;
    top             : 0;
    left            : 0;
    opacity         : 0;
    width           : 100%;
    z-index         : -100;
    transition      : all 0.3s ease-in;
  }

  &:hover {
    color          : #ffffff;
    text-decoration: none;

    &:before {
      opacity: 1;
    }
  }
}

// Body title
.field--name-body {
  h1 {
    @extend .container;
  }
}

// Admin
.toolbar-fixed {
  &.toolbar-tray-open {
    .dialog-off-canvas-main-canvas {
      margin-top: 130px;

      #navbar-top {
        margin-top: 78px;
      }
    }

    #main-wrapper {
      nav.tabs {
        z-index: 99999999;
        opacity: .35;
        top    : 209px;

        &:hover {
          opacity: .95;
        }
      }
    }
  }

  #toolbar-administration {
    position: relative;
    z-index : 4000;
  }

  .dialog-off-canvas-main-canvas {
    margin-top: 130px;

    #navbar-top {
      margin-top: 39px;
    }

    #navbar-main {}
  }

  #main-wrapper {
    nav.tabs {
      position        : fixed;
      top             : 187px;
      left            : 0;
      z-index         : 800;
      display         : flex;
      flex-direction  : row;
      background-color: #2a85bb !important;

      .nav-tabs {
        margin-left: auto;
        border     : solid 2px #0079C0;

        .nav-item {
          margin-bottom: 0;

          &.active {
            color: rgba(0, 121, 192, 0.81);

            .nav-link {
              color: rgba(0, 121, 192, 0.81);

              &:hover {
                color: #ffffff;
              }
            }
          }
        }

        .nav-link {
          border-top-left-radius : 0;
          border-top-right-radius: 0;
          color                  : #ffffff;

          &:hover {
            border-color    : #0079C0;
            background-color: #0079C0;
            color           : #ffffff;
          }
        }
      }
    }

  }
}

// Main Background


body {
  background: -webkit-linear-gradient(right, #00ABDC, #223C73);
  background: linear-gradient(to right, #00ABDC, #223C73);
}

.triangle-1 {
  position : fixed;
  left     : 5%;
  top      : 5%;
  z-index  : 500;
  transform: translatey(0px) translatex(0px) rotate(0);
  animation: float 6s ease-in-out infinite;
  @extend .d-print-none;
}

.triangle-2 {
  position : fixed;
  left     : 10%;
  top      : -22%;
  z-index  : 400;
  transform: translatey(0px) translatex(0px) rotate(0);
  animation: float2 8s ease-in-out infinite;
  @extend .d-print-none;
}

.main-back {
  overflow  : hidden;
  max-height: 100vh;
  max-width : 100vw;
  width     : 100vw;
  height    : 100vh;
  position  : fixed;
  top       : 0;
}

@keyframes float {
  0% {
    transform: translatey(0px) translatex(0) rotate(0deg);
  }

  50% {
    transform: translatey(50px) translatex(-50px) rotate(10deg);
  }

  100% {
    transform: translatey(0px) translatex(0) rotate(0deg);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(0px) translatex(0px) rotate(0deg);
  }

  50% {
    transform: translatey(-80px) translatex(80px) rotate(-5deg);
  }

  100% {
    transform: rotate(0) translatey(0px) translatex(0px);
  }
}

@keyframes line1 {
  0% {
    transform: translatey(100%);
  }

  100% {
    transform: translatey(-100%);
  }
}

@keyframes line2 {
  0% {
    transform: translatey(-120%);
  }

  100% {
    transform: translatey(120%);
  }
}

.line1 {
  height          : 100vh;
  width           : 1px;
  position        : fixed;
  top             : 0;
  left            : 15%;
  z-index         : 200;
  background-color: rgba(255, 255, 255, 0.25);
  // transform: translatey(100%);
  // animation: line1 8s linear infinite;
  @extend .d-none, .d-lg-block;
  @extend .d-print-none;
}

.line2 {
  height          : 100vh;
  width           : 1px;
  position        : fixed;
  top             : 0;
  left            : 35%;
  z-index         : 200;
  background-color: rgba(255, 255, 255, 0.25);
  // transform: translatey(-120%);
  // animation: line2 12s linear infinite;
  @extend .d-none, .d-lg-block;
  @extend .d-print-none;
}

.line3 {
  height          : 100vh;
  width           : 1px;
  position        : fixed;
  top             : 0;
  left            : 60%;
  z-index         : 200;
  background-color: rgba(255, 255, 255, 0.25);
  // transform: translatey(100%);
  // animation: line1 6s linear infinite;
  @extend .d-none, .d-lg-block;
  @extend .d-print-none;

}

.line4 {
  height          : 100vh;
  width           : 1px;
  position        : fixed;
  top             : 0;
  left            : 85%;
  z-index         : 200;
  background-color: rgba(255, 255, 255, 0.25);
  // transform: translatey(-120%);
  // animation: line2 8s linear infinite;
  @extend .d-none, .d-lg-block;
  @extend .d-print-none;

}

// Modal
.modal-backdrop {
  background-color: #1D1D4F;

  &.show {
    opacity: 0.95;
  }
}

#modalSearch {
  .modal-content {
    background-color: transparent;
    border          : none !important;

    .modal-header {
      border : none !important;
      display: none;
    }

    .modal-body {
      h2 {
        color      : #ffffff;
        font-family: 'ubuntuB';
        @include font-size(16);
        text-transform: uppercase;
        margin-bottom : 10px;
      }

      #block-formulairederecherche,
      #block-searchapipagesearchblockform {
        width: 100%;

        @include media-breakpoint-down(sm) {
          text-align: center;

          fieldset {
            margin: 0 10% !important;
            width : 80% !important;
          }
        }

        .content {
          width: 100%;
        }

        .search-block-form,
        #search-api-page-block-form-recherche,
        #search-api-page-block-form-recherche--2 {
          @extend .d-flex, .flex-row, .flex-wrap;

          .form-type-search {
            width: 100%;
            @extend .icon, .my-auto;
            position: relative;

            &:before {
              @extend .icon-loupe;
              position : absolute;
              right    : 10px;
              font-size: 34px;
              color    : $valab-light-blue;
            }

            input {
              padding      : 14px 20px;
              height       : inherit;
              border-radius: 10px;
              border       : none;
            }
          }

          #edit-actions,
          #edit-actions--2,
          #edit-actions--3,
          #edit-actions--4 {
            @extend .my-auto;
            position: absolute;
            right   : 10px;
            top     : 57px;
            width   : 41px;
            opacity : 0;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .closer {
    position        : fixed;
    top             : 40px;
    right           : 40px;
    background-image: url('../assets/icons/close-search.svg');
    width           : 68px;
    height          : 68px;
    background-size : cover;
    cursor          : pointer;
    transition      : all 0.3s ease-out;
    transform       : rotate(0);

    &:hover {
      transform: rotate(180deg);
    }

    i {
      &:before {
        display: none;
      }
    }

  }
}


.page-user-login,
.page-user-register,
.page-user-password {
  .backpage {
    color           : #ffffff;
    text-transform  : uppercase;
    font-family     : ubuntuB;
    font-size       : 13px;
    padding         : 18px 34px 18px 54px;
    position        : fixed;
    top             : 58px;
    background-color: #00A5D8;
    border-radius   : 60px;
    left            : 48px;
    border          : solid 1px transparent;
    transition      : all 0.3s ease-out;
    @extend .icon;

    &:hover {
      text-decoration: none;
      border         : solid 1px #ffffff;

      &:before {
        left   : 3px;
        opacity: 1;
      }
    }

    &:before {
      @extend .icon-back-to-top;
      transform : rotate(-90deg);
      font-size : 35px;
      transform : rotate(-90deg);
      font-size : 35px;
      position  : absolute;
      top       : 1px;
      left      : 11px;
      opacity   : 0.8;
      display   : inline-block;
      transition: inherit;
    }
  }

  #page {
    margin-top: 0;
  }

  header,
  footer {
    display: none;
  }

  .password-form {
    @extend .d-flex;
    flex-wrap: wrap;
    @extend .col-md-4, .offset-md-4;
    box-shadow   : 0px 3px 25px #1D1D4F80;
    padding-top  : 50px;
    margin-top   : 170px;
    margin-bottom: 50px;
    background   : #fff;
    border-radius: 25px;
    padding-left : 40px;
    padding-right: 40px;

    .header {
      flex: 1 1 100%;

      h1 {
        text-align : center;
        font-family: 'opensansSB';
        @include font-size(18);
        position      : relative;
        padding-bottom: 30px;
        margin-bottom : 40px;

        &:after {
          content    : "";
          width      : 33px;
          height     : 1px;
          background : $valab-light-blue;
          position   : absolute;
          bottom     : -10px;
          left       : 50%;
          margin-left: -16px;
        }
      }

      &:before {
        content          : '';
        background-image : url('../assets/icons/logovalab.svg');
        background-repeat: no-repeat;
        display          : block;
        margin-left      : auto;
        margin-right     : auto;
        width            : 135px;
        height           : 40px;
        margin-bottom    : 40px;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea,
    select {
      background   : $gray-200 !important;
      height       : 52px;
      border       : solid 2px $gray-200;
      transition   : all 0.3s ease-out;
      margin-bottom: 10px;

      &:focus {
        border    : solid 2px $valab-light-blue;
        box-shadow: none !important;
      }
    }

    label {
      display: none;
    }

    #edit-submit {
      @extend .btn, .btn-linear, .d-flex, .mx-auto;
      margin-top   : 20px;
      margin-bottom: 30px;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .connexion-form {
    @extend .d-flex;
    flex-wrap: wrap;
    @extend .col-md-8, .offset-md-2;
    box-shadow   : 0px 3px 25px #1D1D4F80;
    padding-top  : 50px;
    margin-top   : 170px;
    margin-bottom: 50px;
    background   : #fff;
    border-radius: 25px;

    .header {
      flex: 1 1 100%;

      h1 {
        text-align : center;
        font-family: 'opensansSB';
        @include font-size(18);
        position      : relative;
        padding-bottom: 30px;
        margin-bottom : 40px;

        &:after {
          content    : "";
          width      : 33px;
          height     : 1px;
          background : $valab-light-blue;
          position   : absolute;
          bottom     : -10px;
          left       : 50%;
          margin-left: -16px;
        }
      }

      &:before {
        content          : '';
        background-image : url('../assets/icons/logovalab.svg');
        background-repeat: no-repeat;
        display          : block;
        margin-left      : auto;
        margin-right     : auto;
        width            : 135px;
        height           : 40px;
        margin-bottom    : 40px;
      }
    }

    .register-block {
      @extend .col-sm-6;
      margin-bottom: 20px;

      .field--name-body {
        padding      : 40px;
        text-align   : center;
        background   : $gray-200;
        border-radius: 10px;
      }

      h2 {
        color     : $valab-light-blue;
        text-align: center;
        @include font-size(22);
        margin-bottom: 20px;
      }

      a {
        @extend .btn, .btn-linear, .d-flex, .mx-auto;
        margin-top: 40px;
        display   : inline-block !important;

        &:hover {
          color: #ffffff;
        }
      }
    }

    .login-block {
      @extend .col-sm-6;

      nav.tabs {
        display: none;
      }

      .deja-compte {
        padding   : 10px 40px;
        text-align: center;
      }

      h2 {
        color     : $valab-light-blue;
        text-align: center;
        @include font-size(22);
        margin-bottom: 0;
      }

      .user-login-form {
        //max-width: 400px;
        margin-left               : auto;
        margin-right              : auto;
        background                : white;
        padding                   : 30px 40px;
        margin-bottom             : 40px;
        border-bottom-left-radius : 10px;
        border-bottom-right-radius: 10px;

        input[type="text"],
        input[type="email"],
        input[type="password"],
        textarea,
        select {
          background   : $gray-200 !important;
          height       : 52px;
          border       : solid 2px $gray-200;
          transition   : all 0.3s ease-out;
          margin-bottom: 10px;

          &:focus {
            border    : solid 2px $valab-light-blue;
            box-shadow: none !important;
          }
        }

        label {
          display: none;
        }

        #edit-submit {
          @extend .btn, .btn-linear, .d-flex, .mx-auto;
          margin-top: 20px;

          &:hover {
            color: #ffffff;
          }
        }

        #edit-name--description,
        #edit-pass--description {
          display: none;
        }

        .mdp-oublie {
          display        : block;
          text-align     : center;
          color          : $valab-light-blue;
          text-decoration: underline;
          @include font-size(16);
        }
      }
    }

  }

  .registration-form {
    @extend .col-md-4, .offset-md-4;
    @extend .d-flex, .flex-wrap;
    box-shadow   : 0px 3px 25px #1D1D4F80;
    padding-top  : 50px;
    margin-top   : 170px;
    margin-bottom: 50px;
    background   : #fff;
    border-radius: 25px;

    .header {
      flex: 1 1 100%;

      h1 {
        text-align : center;
        font-family: 'opensansSB';
        @include font-size(18);
        position      : relative;
        padding-bottom: 30px;
        margin-bottom : 40px;

        &:after {
          content    : "";
          width      : 33px;
          height     : 1px;
          background : $valab-light-blue;
          position   : absolute;
          bottom     : -10px;
          left       : 50%;
          margin-left: -16px;
        }
      }

      &:before {
        content          : '';
        background-image : url('../assets/icons/logovalab.svg');
        background-repeat: no-repeat;
        display          : block;
        margin-left      : auto;
        margin-right     : auto;
        width            : 135px;
        height           : 40px;
        margin-bottom    : 25px;
      }
    }

    .registration-block {
      margin-left  : auto;
      margin-right : auto;
      background   : white;
      padding      : 30px 40px;
      margin-bottom: 40px;

      nav.tabs {
        display: none;
      }

      h2 {
        color     : $valab-light-blue;
        text-align: center;
        @include font-size(22);
        margin-bottom: 30px;
      }

      .user-register-form {
        position: relative;

        .info_email {
          position: absolute;
          right   : -33px;
          top     : 7px;

          &:before {
            font-size: 24px;
            color    : #d1d1d5;
          }
        }

        .info_puid {
          position: absolute;
          right   : -33px;
          top     : 76px;

          &:before {
            font-size: 24px;
            color    : #d1d1d5;
          }
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="password"],
      textarea,
      select {
        background   : $gray-200 !important;
        height       : 52px;
        border       : solid 2px $gray-200;
        transition   : all 0.3s ease-out;
        margin-bottom: 10px;

        &:focus {
          border    : solid 2px $valab-light-blue;
          box-shadow: none !important;
        }
      }

      #edit-mail--description {
        display: none;
      }

      #edit-field-subscription-0-value {
        display: none;
      }

      label:not(.form-check) {
        display: none;
      }

      .form-check-label {
        margin-bottom: 15px;
        padding-left : 4px;
        @include font-size(13);
      }

      #edit-submit {
        @extend .btn, .btn-linear, .d-flex, .mx-auto;
        margin-top: 20px;

        &:hover {
          color: #ffffff;
        }
      }

      #edit-name--description,
      #edit-pass--description {
        display: none;
      }

      #edit-field-name-wrapper,
      #edit-field-nom-crm-officiel-wrapper,
      #edit-field-subscription-wrapper,
      #edit-field-message-rapport-wrapper,
      #edit-field-message-systeme-wrapper {
        display: none;
      }
    }
  }

}

.path-user {
  .form-item-confirm-mail {
    display: none;
  }

  .tabs {
    display: none;
  }

  .user-content {
    background-color: #ffffff;
    padding-top     : 60px;
    padding-bottom  : 60px;
  }

  .icon_information {
    width : 20px;
    height: 20px;
  }

  #user-form {
    max-width       : 700px;
    margin-left     : auto;
    margin-right    : auto;
    background-color: #fff;
    padding         : 60px;
    border-radius   : 20px;
    box-shadow      : 0 3px 15px #0000005e;

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea,
    select {
      background   : $gray-200 !important;
      height       : 52px;
      border       : solid 2px $gray-200;
      transition   : all 0.3s ease-out;
      margin-bottom: 10px;

      &:focus {
        border    : solid 2px $valab-light-blue;
        box-shadow: none !important;
      }
    }

    #edit-account {
      @extend .d-flex, .flex-column;

      .form-item-current-pass {
        @extend .order-4;
      }

      .form-item-mail {
        @extend .order-1;
      }

      .form-item-confirm-mail {
        @extend .order-2;
      }

      .form-item-name {
        @extend .order-3;
      }

      #edit-pass {
        @extend .order-5;
      }
    }

    fieldset {
      margin-bottom: 35px;
    }

    label {
      font-family: 'ubuntuM';
      color      : $valab-light-blue;
      @include font-size(22);
    }

    .form-check {
      label {
        @include font-size(16);
        font-family: 'ubuntuR';
        color      : $valab-dark-blue-font;
      }
    }

    .field--name-field-subscription,
    .field--name-field-nom-crm-officiel {
      display: none;
    }

    .field--name-field-message-rapport {
      margin-bottom: 20px !important;
    }

    #edit-submit {
      @extend .btn, .btn-linear;
      text-transform: uppercase;
    }

    #edit-delete {
      text-transform: uppercase;
      border        : none;
      background    : none;
      color         : $valab-dark-blue;
      font-family   : 'ubuntuM';
      padding       : 20px;
      margin-top    : 10px;
    }

    #edit-language {
      summary {
        display: none;
      }
    }
  }
}

.fancybox-bg {
  background-color: #1D1D4F;
  opacity         : 0.8;
}

.page-node-532 {
  .text-interior {
    min-height: 410px;
  }
}

.region-featured-bottom-first {
  background: #fff;

  #block-informationsouscartereferences,
  #block-blocconfigurablepartenaires {
    margin-top      : 40px;
    margin-bottom   : 140px;
    background-color: #ffffff;

    p {
      font-family: 'opensansR';
      @include font-size(12);
    }

    a {
      font-family: 'opensansSB';
      @include font-size(12);
      color: #FF6E05;
    }

    .field--name-body {
      @extend .d-flex;
      @extend .icon;

      &:before {
        @extend .icon-information;
        display: inline-block;
        color  : #D1D1D5;
        @include font-size(25);
        margin-right: 10px;
        @extend .my-auto;
      }

      p {
        display: inline-block;
        @extend .my-auto;
      }
    }
  }
}

.mention_anonymous {
  background: #fff;

  div {
    background-color: #ffffff;

    p {
      font-family: 'opensansR';
      @include font-size(12);
    }

    a {
      font-family: 'opensansSB';
      @include font-size(12);
      color: #FF6E05;
    }

    .contenu {
      @extend .d-flex;
      @extend .icon;
      padding-bottom: 20px;
      padding-top   : 20px;

      &:before {
        @extend .icon-information;
        display: inline-block;
        color  : #D1D1D5;
        @include font-size(25);
        margin-right: 10px;
        @extend .my-auto;
      }

      p {
        display: inline-block;
        @extend .my-auto;
      }
    }
  }
}

// Indicateur de force de MPD
.password-strength__indicator {
  background-color: $valab-dark-blue;
}


// Toast
.toast-wrapper,
.toast-container {
  position   : fixed;
  width      : 50vw;
  max-width  : 50vw;
  margin-left: 25vw;

  @include media-breakpoint-down(sm) {
    max-width  : 90vw;
    width      : 90vw;
    margin-left: 5vw;
  }

  z-index: 300000;
  top    : 260px;
  left   : 0;

  &.old-account {
    top                          : 30px;
    @include fadeInDown($duration: 0.3s, $count: 1, $delay: 0.5s, $function: ease, $fill: both);
  }

  .toast {
    &.hide {
      display: none;
    }

    max-width       : 100%;
    padding         : 16px;
    border-radius   : 6px;
    margin-bottom   : 20px;
    margin-left     : auto;
    margin-right    : auto;
    background-color: #FFFFFF !important;
    color           : #FF6E05 !important;
    font-family     : 'ubuntuR';
    @include font-size(18);
    box-shadow: 0 10px 15px #1D1D4F40;

    .toast-header {
      strong {
        display: none;
      }

      svg {
        display: none;
      }
    }

    .toast-body {
      text-align: center;
    }
  }
}

// Place picto on Windows
.os-win {
  #search-api-page-block-form-recherche {
    .form-type-search {
      &:before {
        top: 10px;
      }
    }
  }
}


// Sitemap
.path-sitemap {
  .user-content {
    @extend .p-0;
  }

  .sitemap {
    background-color: #ffffff;
    @extend .container-fluid, .d-flex, .flex-wrap;
    padding-left  : 42px;
    padding-top   : 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(xxl) {
      padding-left : 10%;
      padding-right: 10%;
    }

    &>.sitemap-item {
      @extend .col-12, .col-md-6, .col-xl-3;
      padding-top: 20px;

      &:first-child {
        ul {
          li:first-child {
            display: none;
          }
        }
      }

      ul {
        display        : flex;
        flex-direction : column;
        list-style-type: none;

        li {
          a {
            padding: 3px 0 !important;
          }
        }
      }

      a {
        font-family: 'ubuntuR';

        &:hover {
          color          : $valab-light-blue;
          text-decoration: none;
        }
      }
    }
  }
}

// Header overflow & button print in body
.text-interior .container {
  &>a.btn_print {
    float: right;
  }
}

.responsivetable {
  width   : 100%;
  overflow: auto;

  table {
    width     : auto;
    box-shadow: none;
    border    : none;
  }
}

// Special case for User Edit

// Civility
#edit-field-civility {
  display: flex;
}

.form-item-field-civility {
  label {
    font-family: 'opensansR';
    @include font-size(16);
    position: relative;
    left    : -20px;
    padding : 0 30px;
  }

  // Checkbox
  [type="radio"] {
    //display: none;
    opacity: .3;
  }

  [type="radio"]:not(:checked)+label:before,
  [type="radio"]:checked+label:before {
    content      : '';
    position     : absolute;
    left         : 0;
    top          : 0;
    width        : 23px;
    height       : 23px;
    border       : 2px solid $valab-light-blue;
    background   : #FFF;
    border-radius: 23px;
    transition   : all .275s;
  }

  /* Aspect de la coche */
  [type="radio"]:not(:checked)+label:after,
  [type="radio"]:checked+label:after {
    content         : '';
    position        : absolute;
    top             : 7px;
    left            : 7px;
    font-size       : 39px;
    color           : #00A0DB;
    line-height     : 0;
    transition      : all .2s;
    background-color: $valab-light-blue;
    ;
    width        : 9px;
    height       : 9px;
    border-radius: 50%;
  }

  /* Aspect non cochée */
  [type="radio"]:not(:checked)+label:after {
    opacity  : 0;
    transform: scale(0) rotate(45deg);
  }

  /* Aspect cochée */
  [type="radio"]:checked+label:after {
    opacity  : 1;
    transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="radio"]:disabled:not(:checked)+label:before,
  [type="checradiokbox"]:disabled:checked+label:before {
    box-shadow      : none;
    border-color    : #bbb;
    background-color: #e9e9e9;
  }

  /* Disabled checked */
  [type="radio"]:disabled:checked+label:after {
    color: #777;
  }

  [type="radio"]:disabled+label {
    color: #aaa;
  }
}

// Matomo deny form
#block-matomodeny {
  @extend .container;

  #edit-actions {
    margin-top   : 20px;
    margin-bottom: 20px;
  }
}

.subscribe-actualites-modal {
  display         : none;
  /* Hidden by default */
  position        : fixed;
  /* Stay in place */
  z-index         : 10000;
  /* Sit on top */
  padding-top     : 360px;
  /* Location of the box */
  left            : 0;
  top             : 0;
  width           : 100%;
  /* Full width */
  height          : 100%;
  /* Full height */
  overflow        : auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */

  /* Modal Content */
  .content {
    position                  : relative;
    background-color          : #fff;
    margin                    : auto;
    padding                   : 0;
    border                    : 1px solid #888;
    width                     : 35%;
    min-width                 : 300px;
    max-width                 : 650px;
    border-radius             : 20px;
    padding                   : 30px;
    display                   : flex;
    flex-direction            : column;
    align-items               : center;
    font-family               : 'ubuntuB';
    color                     : #1A3088;
    box-shadow                : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name    : animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name            : animatetop;
    animation-duration        : 0.4s;

    .picto {
      background-image   : url('../assets/icons/icon_actu.svg');
      width              : 60px;
      height             : 70px;
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : contain;
    }

    .close {
      position     : absolute;
      right        : 20px;
      position     : absolute;
      top          : 10px;
      border       : 1px solid gray;
      border-radius: 50%;
      padding      : 6px 12px;
    }

    .title {
      font-size: 24px;

      &::after {
        content         : '';
        width           : 40px;
        height          : 1px;
        background-color: #00ABDC;
        display         : block;
        margin-left     : 43%;
        margin-bottom   : 30px;
        margin-top      : 20px;
      }
    }

    form {
      display       : flex;
      flex-direction: column;

      .top {
        display: flex;
        gap    : 30px;

        label {
          text-transform: capitalize;

          input {
            margin-right: 12px;

          }
        }

      }

      .custom-switch {
        margin-bottom: 20px;

        &.form-switch-md {
          @include switch('md');
        }

        label {
          font-size  : 12px !important;
          color      : grey;
          font-weight: 400;
        }
      }

      .btn-submit {
        background: none;
        color     : inherit;
        border    : none;
        padding   : 0;
        font      : inherit;
        cursor    : pointer;
        outline   : inherit;
      }
    }
  }
}
