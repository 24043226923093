.tpl-double-equal-width {
    background-color: transparent;
    display:flex;
    @include media-breakpoint-down(sm) { flex-direction: column; }
    .content {
        display: inline-block;
        width:50%;
        @include media-breakpoint-down(sm) { width:100%; }
        margin: 0 auto;
        padding: 90px 0;
        background:url(../images/rangee-50-50-1.png);
        background-size: cover;
        @include media-breakpoint-down(sm) {
            padding: 40px 0 60px;
        }
        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                @include fadeInUp($duration: (#{$i*0.3s}), $count: 1,$delay:(#{$i*0.3s}), $function: ease, $fill: both);
            }
        } 
        .container{
            text-align: center;
            max-width: map-get($container-max-widths, xxl) / 2;
            @include media-breakpoint-down(xl) {
                max-width: map-get($container-max-widths, xl) / 2;
            }
            @include media-breakpoint-down(sm) {
                max-width: map-get($container-max-widths, sm);
            }
            @include media-breakpoint-down(xs) {
                max-width: map-get($container-max-widths, xs);
            }
            h2, h3{
                font-family: 'ubuntuM';
                @include font-size(22);
                color:#fff;
                @include media-breakpoint-down(sm) {
                    @include font-size(22);
                    line-height: 28px;
                }
            }
            p {
                @include font-size(18);
                color:#fff;
                font-family: 'opensansR';
                text-align: center;
                line-height:30px;
                @include media-breakpoint-down(sm) {
                    line-height: 25px;
                    @include font-size(18);
                }
            }
            .btn {
                margin-bottom: -30px;
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                    color:#02729c; 
                }
            }

        }
        &:last-child{
            background:url(../images/rangee-50-50-2.png);
            background-size: cover;
        }
    }
}