$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 20px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 3px;
$hamburger-layer-color         : #1A3088;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.9;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
// Hamburgers
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
.navbar-brand{
	img{
		height: 40px;
		transition: all 0.3s ease-out;
	}
	.slogan{
		font-family: 'opensansSB';
		color:$valab-dark-blue;
		@include font-size(13);
		padding-top:5px;
		b{
			color:$valab-light-blue;
		}
	}
}
.block-language {
	@extend .my-auto;
	nav.links {
		@extend .d-flex;
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		.nav-link {
			margin-left: 10px;
			margin-right: 10px;
			&:first-child {
				margin-left: 0;
			}
			&:after {
				content:'';
				display: block;
				width: 100%;
				height: 3px;
				background-color: transparent;
				position: relative;
				top: 11px;
			}
			&.is-active {
				&:after {
					background-color: $valab-yellow;
				}
			}
			.language-link {
				text-transform: uppercase;
				@include font-size(14);
				line-height: 16px;
				font-family: 'ubuntuB';
				color: $valab-dark-blue-font;
				opacity: 0.5;
				padding: 0;
				letter-spacing: 1.4px;
				&:hover {
					text-decoration: none;
					opacity: 1;
				}
				&:focus {
					box-shadow: none;
				}
				&.is-active {
					opacity: 1;
				}
			}
		}

	}
}
#header {
	@extend .fixed-top, .d-print-none;
	top: 0;
	transition: all 0.3s linear;
	#navbar-top {
		background-color: #ffffff;
		padding-top: 0;
		@extend .d-none, .d-lg-flex;
		.region-secondary-menu {
			@extend .ml-auto;
			border-bottom: 1px solid #1A308833;
			.nav-link {
				border-top: 1px solid #1A308833;
				border-left: 1px solid #1A308833;
				@include font-size(14);
				line-height: 16px;
				font-family: 'ubuntuR';
				color: $valab-dark-blue-font;
				&.is-active {
					color: $valab-yellow;
				}
			}
		}
	}

	.menu--account {
		.nav-item{
			&:nth-child(2) .nav-link{
				@extend .icon, .d-flex;
				&:before {
					@extend .icon-dowlond-icon;
					opacity: 0.5;
					margin-right: 12px;
					font-size: 16px;
				}
			}
			&:nth-child(3) .nav-link{
				background-color: #1A30881A;
				font-family: 'ubuntuB' !important;
				font-size:12px !important;
				text-transform: uppercase;
				letter-spacing: 1.2px;
			}
			&:last-child .nav-link{
				background-color: #00A5D8 !important;
				color: #ffffff !important;
				font-family: 'ubuntuB' !important;
				font-size:12px !important;
				text-transform: uppercase;
				letter-spacing: 1.2px;
				&:hover {
					background-color: $valab-yellow;
				}
				&.is-active {
					background-color: $valab-yellow;
				}
			}
		}
		.nav-link {
			padding-left: 36px;
			padding-right: 36px;
			padding-top: 15px;
			padding-bottom: 15px;
			transition: all 0.1s ease-in;
			
		}
	}
	#block-valab-account-menu{
		ul.nav{
			& > li{
				&:last-child{
					a{
						background-color: $valab-light-blue !important;
					}
				}
			}
		}
		ul.dropdown-menu {
			box-shadow: 0 10px 25px #223C7340;
			border:none;
			border-radius: 5px;
			padding: 30px 40px;
			top: 96%;
			@include fadeInLeft($duration: 0.3s,$count: 1,$delay: 0,$function: ease,$fill: both );
			
			.dropdown-item {
				padding: 0;
				margin-bottom: 10px;
				&:hover, &.active {
					background-color: transparent;
				}
			}
			a {
				@include font-size(14);
				font-family: 'ubuntuB';
				color:$valab-dark-blue-font;
				&:after {
					content: '';
					display: block;
					width: 20px;
					height: 1px;
					background-color: $valab-dark-blue-font;
					transition: all 0.3s ease-out;
				}
				&:hover, &.is-active {
					color: $valab-yellow;
					text-decoration: none;
					background-color: transparent;
					&:after {
						width: 100%;
						background-color: $valab-yellow;
					}
				}
			}
		}
	}
	#navbar-main {
		padding-left: 42px;
		padding-right: 42px;
		padding-bottom: 0;
		padding-top: 0;
		background-color: #FFFFFF !important;
		transition: all 0.3s linear;
		box-shadow: 0 9px 10px #223C7333;
		@include media-breakpoint-up(xxl) {
			padding-left: 10%;
			padding-right: 8%;
		}
		@extend .d-flex,.flex-row;
		.navbar-brand {
			position: absolute;
			top: -25px;
			transition: all 0.3s ease-out;
			width: 303px;
			@include media-breakpoint-down(lg) {
				top: -45px;
				left: 30px;
			}
			.slogan {
				transition: all 0.3s ease-out;
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			@include media-breakpoint-down(lg) {
				width: 163px;
				.slogan{
					b{
						display: block;
					}
					@include media-breakpoint-down(sm) {
						display:none
					}
				}
			}
			@media screen and (max-width:1680px){
				width: 173px;
				white-space: unset;
			}
		}
		.navbar-toggler {
			@extend .ml-auto;
			border: none;
			position: absolute;
			right: 9px;
			top: 14px !important;
			outline: none !important;
			.navbar-toggler-icon {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(28, 34, 95, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
			}

		}
		.menu--main {
			@extend .ml-auto, .d-flex;
		} 
		.navbar-nav {
			@extend .my-auto;
			.nav-item {
				padding-top:17px;
				padding-bottom: 25px;
				a.nav-link{ text-transform: unset; }
				// Homepage
				&:first-child {
					a {
						padding: 5px 15px 0 0;
						@extend .icon;
						&:before {
							@extend .icon-home-alt;
							font-size: 22px;
							position: relative;
							top: -5px;
							left: -10px;
							@include media-breakpoint-down(xl) {
								top: 0;
								left: 0;
							}
						}
					}
				}
				.dropdown-menu {
					box-shadow: 0 10px 25px #223C7340; 
					border:none;
					border-radius: 5px;
					padding: 30px 40px;
					top: 96%;
					@include fadeInLeft($duration: 0.3s,$count: 1,$delay: 0,$function: ease,$fill: both );
					.dropdown-item {
						padding: 0;
						margin-bottom: 10px;
						&:hover, &.active {
							background-color: transparent;
						}
					}
					a {
						
						@include font-size(14);
						font-family: 'ubuntuB';
						color:$valab-dark-blue-font;
						&:after {
							content: '';
							display: block;
							width: 20px;
							height: 1px;
							background-color: $valab-dark-blue-font;
							transition: all 0.3s ease-out;
						}
						&:hover, &.is-active {
							color: $valab-yellow;
							text-decoration: none;
							background-color: transparent;
							&:after {
								width: 100%;
								background-color: $valab-yellow;
							}
						}
					}
				}
				.nav-link {
					@include font-size(14);
					font-family: 'ubuntuB';
					color:$valab-dark-blue-font;
					text-transform: uppercase;
					padding-bottom: 0;
					padding-left: 20px;
					padding-right: 20px;
					transition: all 0.3s ease-out;
					@include media-breakpoint-up(xxl) {
						@include font-size(16);
					}
					@include media-breakpoint-down(xl) {
						padding-left: 10px;
						padding-right: 10px;
					}
					&:hover {
						color:$valab-yellow;
					}
					&.is-active, &.active {
						color:$valab-yellow;
					}
					&.dropdown-toggle {
						&:after {
							@extend .d-lg-none;
						}
					}
				}
			}
		}
		.search-link {
			@extend .my-auto;
			@include font-size(20);
			cursor: pointer;
			margin-left: 20px;
			i {
				position: relative;
				top: -6px;
				@include media-breakpoint-down(xl) {
					top: 0;
				}
				&:before {
					color:$valab-dark-blue-font;
					transition: all 0.3s ease-out;
				}
			}
			&:hover {
				i {
					&:before {
						color:$valab-yellow;
					}
				}
			}
		}
		
	}
}
.user-logged-in {
	.menu--account {
		.nav-item{
			&:nth-child(4) .nav-link{
				background-color: #1A3088;
				color: #ffffff !important;
				text-transform: uppercase;
				font-size: 12px !important;
				font-family: 'ubuntuB' !important;
			}
		}
		.nav-link {
			padding-left: 36px;
			padding-right: 36px;
			padding-top: 15px;
			padding-bottom: 15px;
			transition: all 0.1s ease-in;
			
		}
	}
}

body.scrolled:not(.toolbar-horizontal) {
	#header {
		top: -53px;
		#navbar-main {
			top: 0;
			.navbar-brand {
				top: 2px;
				.slogan{ opacity: 0; }
				img {
					height: 30px;
				}
			}
			.navbar-nav {
				.nav-item {
					padding-bottom: 12px; 
					padding-top: 5px;
				}
			}

		}
	}
}


@include media-breakpoint-up(lg) { 
	#block-valab-account-menu ul li:hover> ul {
		display: block
	}
	#navbar-main .collapse ul li:hover> ul {
		display: block
	}
	#navbar-main .collapse ul ul {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 250px;
		display: none
	}
    /*******/
    #navbar-main .collapse ul ul li {
    	position: relative
    }
    #navbar-main .collapse ul ul li:hover> ul {
    	display: block
    }
    #navbar-main .collapse ul ul ul {
    	position: absolute;
    	top: 0;
    	left: 100%;
    	min-width: 250px;
    	display: none
    }
    /*******/
    #navbar-main .collapse ul ul ul li {
    	position: relative
    }
    #navbar-main .collapse ul ul ul li:hover ul {
    	display: block
    }
    #navbar-main .collapse ul ul ul ul {
    	position: absolute;
    	top: 0;
    	left: -100%;
    	min-width: 250px;
    	display: none;
    	z-index: 1
    }
}
body.user-logged-in{
	#block-valab-account-menu{
		a[href="/user/login"],a[href="/en/user/login"],a[href="/de/user/login"]{ display:none; } 
	}
	@include media-breakpoint-up(md) {
		#block-valab-account-menu.menu--account ul{
			.nav-item{
				&:nth-child(4) .nav-link{
					background-color: #1A30881A !important;  
				}
			}
		}
	}
}
body:not(.user-logged-in){
	#block-valab-account-menu{
		a[href="/"],a[href="/en"],a[href="/de"]{ display:none; }
	}
	@include media-breakpoint-down(md) {
		.navigation.menu--account{
			li.menu-item--expanded{
				display: none;
			}
		}
	}
}

/**
Debug Windows Icon Font
*/
.os-win{
	#header{
		.menu--account {
			.nav-item{
				&:nth-child(2) .nav-link{
					&:before {
						line-height:23px !important;
						height:17px !important;
					}
				}
			}
		}
	}
	.btn_print{
		&:before{
			line-height:41px !important;
		}
	}
	.btn_analyse{
		line-height:30px;
	}
}