
.ck-editor__editable {
	.tpl-media-left, .tpl-media-right {
		margin-top: -1px !important;
	}
}

.tpl-media-left, .tpl-media-right {
	@extend .d-flex, .flex-column, .flex-md-row;
	margin-top: -1px;
	.media {
		width: calc(100% / 3);
		position: relative;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		.embedded-entity {
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			overflow: hidden;
			@include media-breakpoint-up(xxl) {
				position: initial;
			}
			@include media-breakpoint-down(sm) {
				position: initial;
			}
		}
		img {
			@extend .img-fluid;
			width: 100%;
			object-fit:cover;
			height: 100%;
			@include media-breakpoint-down(sm) {
				max-height: 400px;
			}
		}

		figure{
			width: 100%;
			height: 100%;
			> div {
				width: 100%;
				height: 100%;
				.embedded-entity{
					width: 100%;
					height: 100%;
					img{
						@extend .img-fluid;
						width: 100%;
						object-fit:cover;
						height: 100%;
						@include media-breakpoint-down(sm) {
							max-height: 400px;
						}

					}
				}
			}
		}
		// CKEditor specials
		.cke_widget_wrapper {
			height: 100%;
			drupal-entity {
				height: 100%;
				.embedded-entity {
					position: initial;
				}
				p {
					margin: 0;
				}
			}
		} 
	}
	.content {
		width: calc(100% / 1.5);
		padding: 90px 0;
		@extend .d-xxl-flex;
		@include media-breakpoint-down(sm) {
			width: 100%;
			padding: 30px 35px;
		}
		.container {
			max-width: map-get($container-max-widths, xxl) / 1.8;
			@include media-breakpoint-up(xxl) {
				margin-top: auto;
				margin-bottom: auto;
			}
			@include media-breakpoint-down(xl) {
				max-width: map-get($container-max-widths, xl) / 1.8;
			}
			@include media-breakpoint-down(sm) {
				max-width: map-get($container-max-widths, sm);
			}
			@include media-breakpoint-down(xs) {
				max-width: map-get($container-max-widths, xs);
			}
			p {
				line-height: 26px;

			}
			h2, h3{

			}
			h2 {
				@include media-breakpoint-down(sm) { @include font-size(25); }
			}
		}
	}
}
body:not(.cke_editable) {
	.tpl-media-left {
		@include fadeInLeft($duration: 0.5s,$count: 1,$delay: .5s,$function: ease,$fill: both );
		.content {
			background-color: #F3F3F6;
			.container {
				@include media-breakpoint-up(xxl) {
					margin-left: 10%;
				}
			}
		}

	}
	.tpl-media-right {
		@include fadeInRight($duration: 0.5s,$count: 1,$delay: .5s,$function: ease,$fill: both );
		.media {
			@extend .order-1, .order-md-2;
		}
		.content {
			background-color: #FFFFFF;
			@extend .order-2, .order-md-1;
		}
	}
}


.media-click {
	.media {
		.embedded-entity {
			background-color: #ffffff;
			cursor: pointer;
			@extend .d-flex;
			img {
				width: 100%;
				height: 100%;
				display: block;
				padding: 30px;
				max-height: unset;
				object-fit: contain;
				@extend .my-auto;
			}
		}
	}
}