/* MIXINS */

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
	.btn-#{$color} a {
		@include button-variant($value, $value);
	}
}

@each $color,
$value in $theme-colors {
	.btn-outline-#{$color} a {
		@include button-outline-variant($value);
	}
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
		);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
		);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-display: swap;
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}


/// Given a font size in pixels, reproduces that font size in rems.
/// @access public
/// @param {Length} $size - Font size
/// @example scss - Usage
///   .foo {
///     @include font-size(16px);
///   }
/// @example css - Result
///   .foo {
///     font-size: 16px;
///     font-size: 1rem;
///   }
@mixin font-size($size) {
	@if unitless($size) {
		$size: $size * 1px;
	}

	font-size: $size;
	font-size: ($size / $base-font-size) * 1rem;
}

@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
      $index: 2rem;
      $mainVal: 1.5rem;
  } @else if $res == 'lg' {
      $index: 3rem;
      $mainVal: 2rem;
  } @else if $res == 'xl' {
      $index: 4rem;
      $mainVal: 2.5rem;
  }

  margin-bottom: $index / 2; /* JUST FOR STYLING PURPOSE */

  .form-check-input {
      height: $mainVal;
      width: calc(#{$index} + 0.75rem);
      border-radius: $mainVal * 2;
  }
}
