/* VARIABLES */

$base-font-size: 16px;

$valab-dark-blue: #223C73;
$valab-dark-blue-font: #1A3088;
$valab-light-blue: #00A0DB;
$valab-yellow: #F69424;


// colors
$primary-shade: #A8CF45;
$primary-light: lighten($primary-shade, 37%);
$primary-dark: darken($primary-shade, 12%);
$accent-shade: #0079C0;
$accent-light: lighten($accent-shade, 37%);
$accent-dark: darken($accent-shade, 12%);
// Reassign color vars to semantic color scheme
$red: #dc3545;
$yellow: #ffc107;
$orange: #FF6E05;
$green: #28a745;
$cyan: #17a2b8;
$gray-100: #f8f9fa !default;
$gray-200: #F3F3F6 !default;
$gray-400: #A8A8BF !default;
$gray-800: #343a40 !default;
$theme-colors: ( primary: $accent-shade, secondary: $primary-shade, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
$brand-primary: $accent-shade;
//$brand-success: $green;
//$brand-info: $teal;
//$brand-warning: $orange;
//$brand-danger: $red;
$brand-inverse: $primary-shade;
// Body
//
// Settings for the `<body>` element.
//$body-bg: $white;
//$body-color: $gray-dark;
$inverse-bg: $primary-shade;
//$inverse-color: $gray-lighter;
// Links
//
// Style anchor elements.
$link-color: $accent-shade;
$link-decoration: none;
$link-hover-color: $accent-dark;
$link-hover-decoration: underline;
// Comments
$comment-spacer-x: 1.25em;
$comment-spacer-y: 1.25em;
// Responsive font sizes
$enable-responsive-font-sizes: true;


$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1500px
	) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1400px
	) !default;