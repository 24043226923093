.document-interior {
	background-color: #ffffff;
	ul {
		list-style: none !important;
		padding-left: 0 !important;
		padding: 30px 0;
		margin-bottom: 0;
		li {
			border-bottom: solid 1px #D1D1D5;
			padding: 10px 0;
		}
	}
}
body:not(.cke_editable) {

}
.document-interior {
	@include fadeInUp($duration: 0.3s,$count: 1,$delay: .2s,$function: ease,$fill: both );
}