.text-interior {
	background-color: #ffffff;
	padding-top: 30px;
	padding-bottom: 30px;
}
body:not(.cke_editable) {
	.text-interior {
		@include media-breakpoint-up(xl) {
			@include fadeInUp($duration: 0.3s,$count: 1,$delay: .2s,$function: ease,$fill: both );
		}
		
	}
}