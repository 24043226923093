.tpl-full-width {
	background-color: transparent;
	.content {
		max-width: 60%;
		@include media-breakpoint-down(xl) {
			max-width: 70%;
		}
		@include media-breakpoint-down(lg) {
			max-width: 90%;
		}
		margin: 0 auto;
		padding: 90px 0;
		@include media-breakpoint-down(sm) {
			padding: 40px 0 70px;
		}
		h3{
			@include font-size(28);
			font-family: 'opensansL';
			color: #ffffff;
			text-align: center;
			line-height: 40px;
			@include media-breakpoint-down(sm) {
				@include font-size(28);
			}
			@include fadeInUp($duration: 0.5s,$count: 1,$delay: 0.2s,$function: ease,$fill: both );
		}
		p {
			@include font-size(18);
			font-family: 'opensansR';
			color: #ffffff;
			text-align: center;
			@include media-breakpoint-down(sm) {
				@include font-size(18);
			}
			@include fadeInUp($duration: 0.5s,$count: 1,$delay: 0.5s,$function: ease,$fill: both );
		}
		.btn {
			margin-bottom: -30px;
			@include fadeInUp($duration: 0.5s,$count: 1,$delay: 0.8s,$function: ease,$fill: both );
			&:hover {
				background-color: rgba(255, 255, 255, 1);
				color:#02729c;
			}
		}
	}
}