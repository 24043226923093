// Aide textuelle pour l'insertion d'iframe
.cke_dialog_ui_vbox{
    & > table > tbody > tr:first-child > td:first-child .cke_dialog_ui_vbox{
        .cke_dialog_ui_input_text{
            &:after{
                content:"Pour dailymotion, vous devez intégrer les URLS de type 'Embed' disponible dans les liens de partage";
                font-size: 10px;
                display: block;
            }
        }
    }
}



// Better inline fields in popups (youtube)
.cke_dialog_ui_hbox .cke_dialog_ui_checkbox{
    display: flex;
    margin: 10px 0;
    label{
        font-size: 12px;
        font-weight: 500 !important;
        margin-left: 4px !important;        
    }
}


// ckEditor

body.cke_editable,
.ck-editor__editable {
    font-family: "opensansR";
    color: #223C73;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background: #ffffff !important;
    * {
        animation-name: none !important;
    }
}
.cke_wysiwyg_frame, .cke_editable, .ck-editor__editable {
    background-color: #ffffff;
    .tpl-media-left, .tpl-media-right, .tpl-full-width, .document-interior, .text-interior, .tpl-double-equal-width,  {
        border: solid 2px $valab-dark-blue;
    }
    .tpl-full-width, .tpl-double-equal-width {
        background-color: #1773a7;
    }
}
.cke_reset_all .cke_dialog_body label {
    display: inline-block !important;
}


.cke_panel_listItem {
    a {
        background-color: #ffffff;
    }
}

.cke_toolbox {
    z-index: 30000;
}
