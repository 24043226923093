/* Import default styles */
@import "../../style";

/**
*   Strate Header
*/

.page_head {
  overflow: hidden;
  position: relative;

  // @include media-breakpoint-up(xxl) {
  //   height: 87vh !important;
  // }
  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
    padding-top   : 40px;
    padding-left  : 25px;
    padding-right : 25px;
  }

  &>.image {
    position  : absolute;
    top       : 0;
    right     : 0;
    height    : 100%;
    opacity   : 1;
    transition: all 0.3s linear;

    @include media-breakpoint-down(lg) {
      opacity: 0.7;
    }

    img {
      height: auto;
      width : 100%;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .container {
    z-index : 999; // Force on top of image
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .text-container {
      padding-top: 90px;

      @media screen and (min-height:1000px) {
        padding-top: 130px;
      }

      @media screen and (min-height:850px) {
        padding-top: 10%;
      }

      // Set dynamic width
      max-width: map-get($container-max-widths, xxl) / 1.35;

      @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, xl) / 1.35;
      }

      @include media-breakpoint-down(sm) {
        max-width  : map-get($container-max-widths, sm);
        padding-top: 0;
      }

      @include media-breakpoint-down(xs) {
        max-width: map-get($container-max-widths, xs);
      }

      h1.titre {
        color: #fff;
        @include font-size(33);
        font-family                : 'ubuntuM';
        @include fadeInUp($duration: 1s, $count: 1, $delay: 0, $function: ease, $fill: both);

        @include media-breakpoint-down(sm) {
          text-align: center;
          @include font-size(30);
        }
      }

      span.texte {
        color                      : #fff;
        @include fadeInUp($duration: 1s, $count: 1, $delay: 0.3s, $function: ease, $fill: both);
        @include font-size(17);

        @include media-breakpoint-down(sm) {
          text-align : center;
          display    : block;
          line-height: 27px;
        }
      }

      .homepage-menu {
        margin-top: 40px;
        display   : block;

        @include media-breakpoint-down(sm) {
          margin-top   : 30px;
          margin-bottom: 80px;
        }

        ul {
          list-style-type: none;
          display        : flex;
          justify-content: center;
          align-items    : center;
          flex-wrap      : wrap;

          @include media-breakpoint-down(sm) {
            padding        : 0 20px;
            align-items    : flex-start;
            flex-direction : row;
            justify-content: space-around;
          }

          li {
            min-width: 27%;
            margin   : 10px 20px;
            position : relative;

            @for $i from 1 through 6 {
              &:nth-of-type(#{$i}) {
                @include fadeInRight($duration: (#{$i*0.3s}), $count: 1, $delay:(#{$i*0.1s}), $function: ease, $fill: both);

                a {
                  .border-1 {
                    &:before {
                      animation: 0.7s ease-in $i*0.1s border-1-before;
                    }

                    &:after {
                      animation: 0.7s ease-in $i*0.1s border-1-after;
                    }
                  }

                  .border-2 {
                    &:before {
                      animation: 0.7s ease-in $i*0.1s border-2-before;
                    }

                    &:after {
                      animation: 0.7s ease-in $i*0.1s border-2-after;
                    }
                  }
                }
              }

            }

            @include media-breakpoint-down(sm) {
              width : 50%;
              margin: 0 0;

              &:nth-child(1),
              &:nth-child(3) {
                padding-right: 40px;

                a img {
                  max-width: 70px !important;
                  width    : 40px;
                }
              }

              &:nth-child(2) {
                padding-left: 40px;
              }

              &:nth-child(4) {
                padding-left: 40px;

                a img {
                  min-width: 54px;
                }
              }
            }

            &:after {
              content          : '';
              display          : block;
              background-size  : 100%;
              background-repeat: no-repeat;
              position         : absolute;
              left             : 50%;
              transform        : translate(-50%, 0);
              z-index          : 300;
              opacity          : 0;
              transition       : all 0.3s ease;
            }

            &:nth-child(1) {
              &:after {
                background-image: url('../images/menupicto1.svg');
                width           : 50px;
                height          : 65px;
                top             : 22px;
              }
            }

            &:nth-child(2) {
              &:after {
                background-image: url('../images/menupicto2.svg');
                width           : 50px;
                height          : 65px;
                top             : 22px;
              }
            }

            &:nth-child(3) {
              &:after {
                background-image: url('../images/menupicto3.svg');
                width           : 50px;
                height          : 65px;
                top             : 22px;
              }
            }

            &:nth-child(4) {
              &:after {
                background-image: url('../images/menupicto4.svg');
                width           : 30px;
                height          : 65px;
                top             : 22px;
                left            : 46%;
              }
            }

            &:nth-child(5) {
              &:after {
                background-image: url('../images/menupicto5.svg');
                width           : 37px;
                height          : 65px;
                top             : 22px;
              }
            }

            &:hover {
              &:after {
                left   : 52%;
                opacity: 1;
                top    : 18px;
              }

              &:nth-child(4) {
                &:after {
                  left: 48%;
                }
              }
            }

            a {
              display    : block;
              color      : #fff;
              text-align : center;
              font-family: 'ubuntuM';
              position   : relative;
              overflow   : hidden;
              padding    : 20px 0;
              width      : 100%;
              @include font-size(23);

              .title {
                @include media-breakpoint-down(sm) {
                  display: block;
                  @include font-size(15);
                }
              }

              @include media-breakpoint-down(sm) {
                @include font-size(16);
                @include font-size(20);
                line-height: 21px;
                text-align : center;
                padding    : 0 0;
              }

              .border-1 {
                &:before {
                  content         : "\a0";
                  position        : absolute;
                  left            : 0;
                  top             : -100%;
                  width           : 1px;
                  height          : 100%;
                  background-color: $valab-dark-blue;
                  transition      : all 0.4s ease;

                  @include media-breakpoint-down(sm) {
                    display: none;
                  }

                }

                &:after {
                  content         : "\a0";
                  position        : absolute;
                  right           : -100%;
                  top             : 0;
                  width           : 100%;
                  height          : 1px;
                  background-color: $valab-dark-blue;
                  transition      : all 0.4s ease;

                  @include media-breakpoint-down(sm) {
                    display: none;
                  }
                }
              }

              .border-2 {
                &:before {
                  content         : "\a0";
                  position        : absolute;
                  right           : 0;
                  top             : 100%;
                  width           : 1px;
                  height          : 100%;
                  background-color: $valab-dark-blue;
                  transition      : all 0.4s ease;

                  @include media-breakpoint-down(sm) {
                    display: none;
                  }
                }

                &:after {
                  content         : "\a0";
                  position        : absolute;
                  left            : -100%;
                  bottom          : 0;
                  width           : 100%;
                  height          : 1px;
                  background-color: $valab-dark-blue;
                  transition      : all 0.4s ease;

                  @include media-breakpoint-down(sm) {
                    display: none;
                  }
                }
              }

              &:hover {
                text-decoration: none;

                img {
                  transform: scale3d(1.2, 1.2, 1.2);
                }

                .border-1 {
                  &:before {
                    top       : 0;
                    transition: all 0.4s ease;
                  }

                  &:after {
                    right     : 0;
                    transition: all 0.4s ease;
                  }
                }

                .border-2 {
                  &:before {
                    top       : 0;
                    transition: all 0.4s ease;
                  }

                  &:after {
                    left      : 0;
                    transition: all 0.4s ease;
                  }
                }
              }

              img {
                max-width    : 50px;
                margin-bottom: 10px;
                display      : block;
                margin       : 0 auto 10px auto;
                z-index      : 400;
                position     : relative;
                transition   : all 0.3s ease-out;
                transform    : scale3d(1, 1, 1);

                @include media-breakpoint-down(sm) {
                  display   : inline;
                  margin-top: 20px;
                  max-width : 70px;
                  width     : 47px;
                }
              }
            }
          }
        }
      }
    }
  }

  .encarts {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    height         : 210px;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      height        : unset;
    }

    .pourquoi-valab {
      @extend .d-md-none;
      margin-bottom: 31px;
    }

    .encart_news_container {
      margin-right: 20px;
      display: flex;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      .encart_news {
        width          : 377px;
        background     : #fff;
        box-shadow     : 0px 3px 25px rgba(34, 60, 115, 0.5);
        padding        : 21px 28px;
        border-top-left-radius  : 10px;
        border-bottom-left-radius  : 10px;
        position       : relative;
        // margin-right:10px;
        transition     : all 0.3s ease-out;
        cursor         : pointer;

        @include media-breakpoint-down(md) {
          border-top-right-radius: 10px;
          border-bottom-left-radius: 0;
        }

        &.solo {
          border-radius: 10px;
        }

        &.hide {
          visibility: hidden;
        }

        @include slideInLeft($duration: 1s, $count: 1, $delay: 0.5s, $function: ease, $fill: both);

        @include media-breakpoint-down(sm) {
          width        : 100%;
          // margin-bottom: 10px;
          max-width    : 470px;
          margin-right : 0;
        }

        &:after {
          content   : "";
          background: url(../images/fleche.svg);
          width     : 25px;
          height    : 15px;
          position  : absolute;
          bottom    : 30px;
          right     : 30px;
          transition: all ease .5s;

          @include media-breakpoint-down(sm) {
            bottom: 19px;
          }
        }

        .label {
          color         : $orange;
          text-transform: uppercase;
          display       : block;
          @include font-size(11);
          font-family: 'ubuntuB';

          @include media-breakpoint-down(sm) {
            @include font-size(13);
          }
        }

        .title {
          display: block;
          color  : $valab-dark-blue-font;
          @include font-size(16);
          font-family: 'ubuntuM';

          @include media-breakpoint-down(sm) {
            @include font-size(18);
          }
        }

        .date {
          display    : block;
          color      : $valab-dark-blue-font;
          font-family: 'opensansR';
          @include font-size(12);

          @include media-breakpoint-down(sm) {
            @include font-size(15);
          }
        }

        a:hover {
          text-decoration: none;
        }

        &:hover {
          padding   : 25px 28px;
          box-shadow: 0px 3px 25px rgba(34, 60, 115, 0.8);

          &:after {
            right: 20px;
          }
        }
      }
      .encart_sub {
        display: flex;
        align-items: center;
        justify-content: center;
        width          : 350px;
        background     : #fff;
        // box-shadow     : 0px 3px 25px rgba(34, 60, 115, 0.5);
        border-left: 1px solid rgb(192, 192, 192);
        padding        : 21px 28px;
        border-top-right-radius  : 10px;
        border-bottom-right-radius  : 10px;
        position       : relative;
        // margin-right:10px;
        transition     : all 0.3s ease-out;
        // cursor         : pointer;
        @include media-breakpoint-down(md) {
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          width: 377px;
          border-left: none;
          border-top: 1px solid rgb(192, 192, 192);
        }

        &.hide {
          visibility: hidden;
        }

        @include slideInLeft($duration: 1s, $count: 1, $delay: 0.5s, $function: ease, $fill: both);

        @include media-breakpoint-down(sm) {
          width        : 100%;
          margin-bottom: 10px;
          max-width    : 470px;
          margin-right : 0;
        }

        // &:after {
        //   content   : "";
        //   background: url(../images/fleche.svg);
        //   width     : 25px;
        //   height    : 15px;
        //   position  : absolute;
        //   bottom    : 30px;
        //   right     : 30px;
        //   transition: all ease .5s;

        //   @include media-breakpoint-down(sm) {
        //     bottom: 19px;
        //   }
        // }

        // .label {
        //   color         : $orange;
        //   text-transform: uppercase;
        //   display       : block;
        //   @include font-size(11);
        //   font-family: 'ubuntuB';

        //   @include media-breakpoint-down(sm) {
        //     @include font-size(13);
        //   }
        // }

        // .title {
        //   display: block;
        //   color  : $valab-dark-blue-font;
        //   @include font-size(16);
        //   font-family: 'ubuntuM';

        //   @include media-breakpoint-down(sm) {
        //     @include font-size(18);
        //   }
        // }

        // .date {
        //   display    : block;
        //   color      : $valab-dark-blue-font;
        //   font-family: 'opensansR';
        //   @include font-size(12);

        //   @include media-breakpoint-down(sm) {
        //     @include font-size(15);
        //   }
        // }

        a:hover {
          text-decoration: none;
        }

        // &:hover {
        //   padding   : 25px 28px;
        //   box-shadow: 0px 3px 25px rgba(34, 60, 115, 0.8);

        //   &:after {
        //     right: 20px;
        //   }
        // }
      }
    }

    .encart_accompagnement {
      background                     : transparent linear-gradient(89deg, #1A2F85 0%, #1C225F 100%) 0% 0% no-repeat padding-box;
      box-shadow                     : 0px 3px 25px #223C7380;
      border-radius                  : 10px;
      display                        : block;
      position                       : relative;
      z-index                        : 999;
      max-width                      : 470px;
      padding                        : 30px;
      color                          : #fff;
      transition                     : all 0.3s ease-out;
      @include slideInRight($duration: 1s, $count: 1, $delay: .8s, $function: ease, $fill: both);

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }

      .titre {
        font-family: 'ubuntuM';
        @include font-size(20);
        color: #fff;

        @include media-breakpoint-down(sm) {
          @include font-size(18);
        }
      }

      .text {
        @include font-size(15);
        color: #fff;

        @include media-breakpoint-down(sm) {
          padding-top: 5px;
        }
      }

      img {
        max-width: 92px;
        position : absolute;
        top      : -40px;
        right    : 30px;

        @include media-breakpoint-down(sm) {
          top  : -57px;
          right: -20px;
        }
      }

      &:after {
        content   : "";
        background: url(../images/fleche-w.svg);
        width     : 25px;
        height    : 15px;
        position  : absolute;
        bottom    : 30px;
        right     : 30px;
        transition: all ease .5s;
      }

      &:hover {
        padding   : 35px 30px;
        box-shadow: 0px 3px 25px rgba(34, 60, 115, 0.8);

        &:after {
          right: 20px;
        }
      }
    }
  }

}

/**
* Strate Efficiency
*/
.efficiency {
  padding: 100px 0;

  @include media-breakpoint-down(sm) {
    padding   : 0;
    margin-top: 50px;
  }

  h2 {
    @include font-size(32);
    font-family                : 'ubuntuM';
    color                      : #fff;
    margin-bottom              : 60px;
    @include fadeInUp($duration: 0.3s, $count: 1, $delay: .1s, $function: ease, $fill: both);

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      @include font-size(25);
    }
  }

  .jobs {
    @extend .row;

    .job {
      @extend .col-md-4;

      @for $i from 1 through 6 {
        &:nth-of-type(#{$i}) {
          @include fadeInRight($duration: (#{$i*0.3s}), $count: 1, $delay:(#{$i*0.1s}), $function: ease, $fill: both);
        }
      }

      margin-bottom: 10px;
      padding-right: 7px;
      padding-left : 7px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }

      .content {
        background   : $white;
        border-radius: 5px;
        padding      : 10px;
        text-align   : center;
        height       : 100%;

        @include media-breakpoint-down(sm) {
          padding       : 0;
          padding-bottom: 20px;

          img {
            border-top-left-radius : 5px;
            border-top-right-radius: 5px;
          }
        }

        h2 {
          color: $valab-dark-blue-font;
          @include font-size(20);
          padding: 12px 0;
          margin : 0;
        }

        h3 {
          color: $valab-light-blue;
          @include font-size(16);

          @include media-breakpoint-down(sm) {
            @include font-size(18);
          }
        }

        ul {
          list-style-type: none;
          text-align     : left;
          margin         : 0 30px 15px 30px;
          padding        : 0;

          &>li {
            border-bottom: 1px solid $gray-200;
            padding      : 5px 5px 5px 35px;
            @include font-size(15);
            color      : $valab-dark-blue-font;
            font-family: 'ubuntuM';

            @include media-breakpoint-down(sm) {
              padding: 10px 5px 10px 35px;
            }

            a {
              @include font-size(15);
              color      : $valab-dark-blue-font;
              font-family: 'ubuntuM';
              position   : relative;
              left       : 0;
              transition : all 0.3s ease-out;

              @include media-breakpoint-down(sm) {
                @include font-size(18);
              }

              &:after {
                content        : "";
                background     : url(../images/flechebleu.svg);
                background-size: 100% 100% !important;
                width          : 17px;
                height         : 11px;
                position       : absolute;
                top            : 4px;
                left           : -28px;
              }

              &:hover {
                left           : 20px;
                text-decoration: none;
                color          : $valab-light-blue;
              }
            }

            &:last-child {
              border: none;
            }
          }
        }

        .image {
          img {
            width: 100%;
          }
        }

        .text {
          text-align: center;

        }
      }
    }
  }

}

.tpl-media-left {

  @include fadeInRight($duration: 0.3s, $count: 1, $delay: .1s, $function: ease, $fill: both);

  .media {
    width   : 50%;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .content {
    width: 50%;
    @extend .d-flex;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .container {
      @extend .my-auto;
    }
  }

  ul {
    list-style-type: none;
    text-align     : left;
    margin         : 0 30px 15px 30px;
    padding        : 0;

    @include media-breakpoint-down(sm) {
      margin: 0 30px 15px 0;
    }

    &>li {

      border-bottom: 1px solid $gray-200;
      padding      : 10px 5px 10px 40px;
      @include font-size(19);
      color      : $valab-dark-blue-font;
      font-family: 'ubuntuM';

      a {
        @include font-size(19);
        color      : $valab-dark-blue-font;
        font-family: 'ubuntuM';
        position   : relative;
        left       : 0;
        transition : all 0.3s ease-out;

        &:after {
          content        : "";
          background     : url(../images/flechebleu.svg);
          background-size: 100% 100% !important;
          width          : 25px;
          height         : 15px;
          position       : absolute;
          top            : 6px;
          left           : -40px;
        }

        @include media-breakpoint-down(sm) {
          @include font-size(15);
        }

        &:hover {
          left           : 20px;
          text-decoration: none;
          color          : $valab-light-blue;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}



// Home anim's

@keyframes border-1-before {
  0% {
    top: -100%;
  }

  50% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

@keyframes border-1-after {
  0% {
    right: -100%;
  }

  50% {
    right: 0;
  }

  100% {
    right: 100%;
  }
}

@keyframes border-2-before {
  0% {
    top: 100%;
  }

  50% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}

@keyframes border-2-after {
  0% {
    left: -100%;
  }

  50% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}
