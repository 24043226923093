.path-node, .path-user, .path-recherche, .node--type-page, .path-sitemap {
	#pagehead {
		position: relative;
		padding: 120px 0;
		@include media-breakpoint-down(xs) {
			padding: 40px 35px;
		}
		background-color: transparent;
		#block-breadcrumbs {
			padding-left: 42px;
			@include media-breakpoint-up(xxl) {
				padding-left: 10%;
			}
			@include media-breakpoint-down(xs) { 
				padding-left: unset;
			}
			z-index: 700;
			position: relative;
			.breadcrumb {
				@extend .d-flex;
				list-style: none;
				padding-left: 0;
				@include media-breakpoint-down(xs) {
					flex-direction: column;
				}
				li {
					font-family: 'opensansR';
					@include font-size(9);
					color: #ffffff;
					text-transform: uppercase;
					@include media-breakpoint-down(sm) {
						@include font-size(12);
					}
					a {
						font-family: 'opensansR';
						@include font-size(9);
						color: #ffffff;
						text-transform: uppercase;
						&:hover {
							text-decoration: none;
						}
						@include media-breakpoint-down(sm) {
							@include font-size(12);
						}
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
					&:after {
						content:'|';

						display: inline-block;
						color: #ffffff;
						@include font-size(9);
						@include media-breakpoint-down(sm) {
							@include font-size(12);
						}
						margin: 0 5px 0 3px;
					}
				}
			}
		}
		.field--name-field-image-entete {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			@include media-breakpoint-down(sm) {
				opacity: 0.35;
			}
			img {
				@extend .img-fluid;
				object-fit:cover;
				height: 100%;
				width: 100%;
			}
		}
		h1 {
			.field--name-field-titre-de-la-page {
				position: relative;
				z-index: 500;
				@include font-size(32);
				font-family: 'ubuntuM';
				margin-bottom: 20px;
				color: #ffffff;
				padding-left: 42px;
				@include media-breakpoint-up(xxl) {
					padding-left: 10%;
				}
				@include media-breakpoint-down(xs) {
					padding-left: unset;
				}
			}
		}
		.field--name-field-chapo {
			position: relative;
			font-family: 'opensansR';
			@include font-size(18);
			color: #ffffff;
			max-width: 840px;
			padding-left: 42px;
			@include media-breakpoint-up(xxl) {
				padding-left: 10%;
			}
			@include media-breakpoint-down(xs) {
				padding-left: unset;
			}
		}

	}
	.contact-interior {

		@include media-breakpoint-down(xs) {
			padding: 40px 35px;
		}
		h2 {
			margin-top: 120px;
			@include media-breakpoint-down(xs) {
				margin-top: 40px;
			}
		} 
		.wow {
			@for $i from 1 through 4 {
				&:nth-of-type(#{$i}) {
					@include fadeInRight($duration: (#{$i*0.3s}), $count: 1,$delay:(#{$i*0.1s}), $function: ease, $fill: both);
				}
			}
		}
		.field--name-field-titre-contact {
			@extend .mx-auto, .text-center;
			@include font-size(28);
			color: #ffffff;
			font-family: 'opensansL';
			max-width: 900px;
		}
		.field--name-field-lien {
			@extend .text-center;
			a {
				@extend .btn, .btn-bordered;
				margin-top: 0px;
			}
		}
		.contact-interior-encart {
			@include fadeInUp($duration: 0.5s,$count: 1,$delay: .5s,$function: ease,$fill: both );
			@extend .mx-auto, .icon;
			max-width: 660px;
			background-color: #ffffff;
			box-shadow: 0 3px 25px #1A308880;
			text-align: center;
			padding-bottom: 30px;
			border-radius: 10px;
			padding-left: 30px;
			padding-right: 30px;
			margin-top: 65px;
			margin-bottom: 65px;
			@include media-breakpoint-down(xs) {
				max-width: 420px;
			}
			&:before {
				@extend .icon-contact;
				margin-right: 10px;
				font-size: 35px;
				position: relative;
				top: 9px;
				color: $valab-light-blue;
				margin-bottom: 5px;
				display: block;
			}
			.field--name-field-titre-de-l-encart-de-conta {
				@include font-size(18);
				font-family: 'ubuntuM';
				margin-bottom: 24px;
				&:after {
					content: '';
					display: block;
					width: 33px;
					height: 1px;
					background-color: $valab-light-blue;
					margin-top: 10px;
					@extend .mx-auto;
				}
			}
			.field--name-field-numero-de-telephone {
				@extend .icon;
				@include font-size(20);
				font-family: 'ubuntuB';
				color:$valab-light-blue;
				margin-top: 20px;
				&:before {
					@extend .icon-Phone;
					margin-right: 10px;
					font-size: 28px;
					position: relative;
					top: 9px;
				}
			}
			.field--name-field-lien-de-lencart-contact {
				a {
					@extend .btn, .btn-linear;
					margin-top: 20px;
				}
			}
		}
	}
	#fonctionnalite {
		background: -webkit-linear-gradient(right, #1A3088, #1D1D4F);
		background: linear-gradient(to right, #1A3088, #1D1D4F);
		padding: 90px 0;
		h2 {
			@include font-size(24);
			font-family: 'ubuntuM';
			color: #ffffff;
			margin-bottom: 60px;
		}
		@include media-breakpoint-down(xs) {
			padding: 40px 35px;
		}
		.interior-fonctionnalite {
			@extend .container;
			.field--name-field-fonctionnalite {
				@extend .row, .no-gutters;
				& > .field__item {
					@for $i from 1 through 9 {
						&:nth-of-type(#{$i}) {
							@include fadeInRight($duration: (#{$i*0.3s}), $count: 1,$delay:(#{$i*0.1s}), $function: ease, $fill: both);
						}
					} 
					@extend .col-12, .col-sm-6, .col-md-4, .col-lg-3;
					border: solid 1px rgba(255, 255, 255, 0.3);
					margin-right: -1px;
					margin-bottom: -1px;
					&:last-child {
						border-right: solid 1px rgba(255, 255, 255, 0.3);
					}
					.paragraph--type--fonctionnalit {
						padding: 30px 10px 60px;
						text-align: center;
						@include font-size(16);
						color: #ffffff;
						line-height: 24px;
						font-family: 'opensansSB';
						cursor: pointer;
						.field--name-field-lie.field--type-link{
							display: none;
						}
						/*&.link {
							&:hover {
								.field--name-field-titre-de-la-fonctionnalit {
									&:before {
										@extend .icon-back-to-top;
										left: 50%;
									}
								}
							}
							.field--name-field-titre-de-la-fonctionnalit {
								&:before {
									@extend .icon-back-to-top;
									transform: rotate(90deg);
									left: 46%;
								}
							}
						}*/
						.field--name-field-pictogramme-de-la-fonctio {
							margin-bottom: 20px;
							.st0 {
								fill: unset;
							}
							svg {
								width: 40px;
								height: 40px;
								transition: all 0.3s ease-out;
								fill:$valab-light-blue;
								path {
									transition: all 0.3s ease-out;
									fill:$valab-light-blue;
								}
							}
						}
						.field--name-field-titre-de-la-fonctionnalit,
						.field--name-field-titre-de-la-fonctionnalit:hover {
							@extend .icon;
							font-size: 16px;
							&:before {
								position: absolute;
								bottom: 20px;
								left: 50%;
								transform: translate(-50%, 0);
								@extend .icon-plus;
								@include font-size(25);
								transition: all 0.3s ease-out 0.3s;
								color:rgba(255, 255, 255, 0.41);
							}
						}
						&:hover {
							.field--name-field-titre-de-la-fonctionnalit {
								&:before {
									color:$valab-yellow
								}
							}
							.field--name-field-pictogramme-de-la-fonctio {
								svg {
									fill:$valab-yellow;
									path {
										fill:$valab-yellow;
									}
								}
							}
						}

					}
				}
			}
		}
	}
	.modal-fonctionnalite {

		.modal-content {
			border-radius: 15px;
			padding: 20px 30px 10px 60px;
			.field--name-field-titre-de-la-fonctionnalit,
			.field--name-field-lie{
				display: none;
			}
			svg{
				width: 40px;
				height: 40px;
				margin: 0 0 20px 0;
				path{
					fill:$valab-light-blue;
				}
			}
			.controls {
				position: absolute;
				width: 120%;
				top: 50%;
				transform: translate(0, -50%);
				height: 10px;
				left: -10%;
				.prev-mod {
					display: block;
					position: absolute;
					left: 0;
					cursor: pointer;
					@extend .icon, .icon-back-to-top;
					&:before {
						font-size: 50px;
						color: white;
						transform: rotate(-90deg);
						display: inline-block;
					}
				}
				.next-mod {
					display: block;
					position: absolute;
					right: 0;
					cursor: pointer;
					@extend .icon, .icon-back-to-top;
					&:before {
						font-size: 50px;
						color: white;
						transform: rotate(90deg);
						display: inline-block;
					}
				}
			}
			.modal-header{
				border-bottom: none;
				svg {
					width: 40px;
					height: 40px;
				}
				.close {
					font-size: 40px;
					font-weight: 100;
					position: absolute;
					right: 20px;
					top: 10px;
					img {
						min-width: 40px;
					}
				}
			} 
			.modal-body {
				.field--name-field-titre-de-la-fonctionnalit,
				h3 {
					@include font-size(22);
					font-family: 'ubuntuR';
					color:$valab-light-blue;
					margin-bottom: 20px;
					&:after {
						content:'';
						width: 43px;
						background-color: $valab-light-blue;
						display: block;
						height: 1px;
						margin-top: 3px;
					}
				}
			}
		}
	}
}

.path-recherche {
	@include media-breakpoint-up(xl) {
		#main-wrapper{
			min-height: calc(100vh - 200px); // Sticky footer
		}
	}
	.user-content {
		background-color: #ffffff;
		padding: 0;

		.search-list  { 
			padding-bottom: 40px;
			padding-left: 4%;
			padding-right: 4%;
			.no-result {
				font-family: 'ubuntuB';
				color: $valab-light-blue;
				margin-bottom: 20px;
				@include font-size(17);
			}
			.search-help {
				ul {
					list-style: none;
					li {
						@extend .icon, .icon-check;
						position: relative;
						margin-bottom: 10px;
						&:before {
							position: absolute;
							left: -22px;
							color: $valab-yellow;
							font-size: 13px;
							top: 2px;
						}
					}
				}
			}
			@include media-breakpoint-up(xxl) {
				padding-left: 10%;
				padding-right: 10%;
			}
			.result {
				padding-bottom: 18px;
				border-bottom: 1px solid #223c732b;
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 0;
			}
		}
		form {
			padding-left: 10%;
			padding-top: 40px;
			padding-bottom: 40px;
			background: #F3F3F6;
			margin-bottom: 40px;
			@extend .d-flex;
			.form-item-keys {
				width: 70%;
				@extend .my-auto;
			}
			#edit-actions {
				margin-left: 15px;
				@extend .my-auto;
				button {
					@extend .btn, .blue, .rounded;
					color: #ffffff;
					font-family: 'ubuntuB';
					text-transform: uppercase;
					@include font-size(13);
					&:hover {
						color: #ffffff;
					}
				}
			}
		}
	}
	h3 {
		margin-top: 15px;
		margin-bottom: 0;
	}
}

// Page de téléchargement de toutes les analyses
body.path-download-analyses{
	.main-content#content{
		@extend .container;
		margin-top:100px;
		section{
			text-align: center;
		}
		a.download{
			@extend .btn, .btn-bordered, .mx-auto;
		}
	}
}

// Maps markers (clients page / contact)
.leaflet-control-attribution{
	display:none;
}
#map{
	.leaflet-shadow-pane{
		display: none !important;
	}
	// Cluster
	.marker-cluster {
		background-color: rgba(#00A5D8, 0.32) !important;
		width: 45px !important;
		height: 45px !important;
		border-radius: 50% !important;
		div{
			background-color: rgba(#00A5D8, 0.65) !important;
			width: 35px !important;
			height: 35px  !important;
			border-radius: 50% !important;
			span{
				color: #ffffff;
				font-family: 'ubuntuB';
				@include font-size(17);
				position: relative;
				top: 3px;
			}
		}
	}
}  

// Menu Page Pourquoi Valab
body:not(.node--type-accueil){
	.menu_homepage{
		position: sticky;
		bottom: 0;
		background:$valab-dark-blue;
		@include media-breakpoint-down(sm) {
			display: none;
		}
		ul{
			@extend .d-flex, .container, .justify-content-center;
			@include media-breakpoint-down(md) { max-width:100%; padding: 0; }
			margin: 0 auto;
			list-style: none;
			li{
				a{
					color:#fff;
					padding: 7px 28px;
					font-family: 'ubuntuM';
					height: 100%;
					@extend .d-flex;
					border-bottom:2px solid transparent;
					@include font-size(16);
					@include media-breakpoint-down(lg) { @include font-size(14); }
					@include media-breakpoint-down(md) { @include font-size(13); }
					@include media-breakpoint-down(sm) { @include font-size(1); color:$valab-dark-blue; }
					svg path, svg rect{
						transition: fill ease .2s;
					}
					img, svg{
						max-height: 40px;
						margin-right:10px;
						min-width: 40px;
						@include media-breakpoint-down(md) { max-height: 30px; margin-right:4px; }
					}
					.title {
						@extend .my-auto;
					}
					&:hover{
						text-decoration: none;
						outline:none;
						border-bottom:2px solid $valab-light-blue;
						background-color: #04205C;
						img, svg path, svg rect{
							fill:$valab-light-blue !important;
						}
					}
				}
				&.active {
					a {
						text-decoration: none;
						outline:none;
						border-bottom:2px solid $valab-light-blue;
						background-color: #04205C;
						img, svg path, svg rect{
							fill:$valab-light-blue !important;
						}
					}
				}
			}
		}
	}
}

// Optionnal webform layout on simple page
.webform_page{
	.node__content > div > div.field--name-body{
		display:none;
	}
	.form-wrapper{
		background: #fff;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
		.webform-wrapper{
			width: 66.666666%;
			padding: 90px 0;
			background:#fff;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			.container {
				@include media-breakpoint-up(xxl) {
					padding-left: 0;
				}
				max-width: map-get($container-max-widths, xxl) / 1.6;
				@include media-breakpoint-down(xl) {
					max-width: map-get($container-max-widths, xl) / 1.4;
				}
				@include media-breakpoint-down(sm) {
					max-width: map-get($container-max-widths, sm);
				}
				@include media-breakpoint-down(xs) {
					max-width: map-get($container-max-widths, xs);
				}
				p {
					line-height: 26px;
				}
			}
		}
		.address-wrapper{
			align-self: flex-start;
			width: 33.33333%;
			padding: 90px 0;
			background:$valab-light-blue;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			p{
				a{
					display:block;
					color:#fff;
					position:relative;
					@include font-size(20);
					font-family: 'ubuntuM';
					margin: 0 0 0 70px;
					padding-left: 15px;
					@include media-breakpoint-down(md) {
						margin: 0 0 0 30px;
					}
					&:first-child{
						@extend .icon, .icon-contact-tel;
						margin-bottom:30px; 
						&:before{
							position:absolute;
							left:-30px; 
							top: -7px;
							@include font-size(28);
						}
					}
					&:last-child{
						font-family: 'opensansR';
						@include font-size(18);
						@extend .icon, .icon-contact-adresse;
						&:before{
							position:absolute;
							left:-30px;
							top: -7px;
							@include font-size(28);
						}
					}
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
	}
}
.modal-fonctionnalite {
	&:first-child {
		color: red !important;
		.modal-content {
			.controls {
				.next-mod {
					display: none;
				}
			}
		}
	}
	&:last-child {
		.modal-content {
			.controls {
				.prev-mod {
					display: none;
				}
			}
		}
	}
}

// Recaptcha
.webform-submission-add-form{
	.g-recaptcha > div{
		margin: 0 auto;
	}
}

// Profil utilisateur, ajout du (i)
.puidwrapper{
	position:relative;
	.info_puid{
		position: absolute;
		top: 55px;
		right: 10px;
	}
}
